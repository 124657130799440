import React, { useState, useEffect, useRef }  from 'react'
import { t }  from 'i18n'

import styled from 'styled-components'

export default function ParkingProducts (props) {
  const [selectedProducts, setSelectedProducts] = useState(props.selectedParkingProducts)

  useEffect(() => {
    props.onChange(selectedProducts)
  }, [selectedProducts])

  const handleProductSelected = (product) => {
    const newSelectedProducts = [...selectedProducts]

    const index = newSelectedProducts.findIndex((nsp) => nsp.id === product.id)
    const updatedProduct = { ...product };
    updatedProduct.price =  product.price || "0.0";

    if(index >= 0) {
      newSelectedProducts.splice(index, 1, updatedProduct)
    } else {
      newSelectedProducts.push(updatedProduct)
    }

    setSelectedProducts(newSelectedProducts)
  }

  const handleProductDeselected = (product) => {
    const newSelectedProducts = [...selectedProducts]
    const index = newSelectedProducts.findIndex((nsp) => nsp.id === product.id)

    if(index >= 0) {
      newSelectedProducts.splice(index, 1)
    }

    setSelectedProducts(newSelectedProducts)
  }

  const renderProducts = () => {
    return props.parkingProducts.map((product) => {
      const selectedProduct = selectedProducts.find((p) => p.id === parseInt(product.id))

      return (
        <ParkingProduct
          key={product.id}
          product={product}
          selectedProduct={selectedProduct}
          handleProductSelected={handleProductSelected}
          handleProductDeselected={handleProductDeselected}
          currency={props.currency}
        />
      )
    })
  }

  return (
    <div className="form-group string optional">
      <label className="string optional">
        {t("sites.form.parking_products")}
        <br />
        {t("sites.form.price_excl_vat")}
      </label>
      <ProductChecks>
        {renderProducts()}
      </ProductChecks>
    </div>
  )
}

function ParkingProduct (props) {
  const [product, setProduct] = useState(props.selectedProduct || props.product)
  const [checked, setChecked] = useState(!!props.selectedProduct)
  const inputEl = useRef()

  useEffect(() => {
    if(checked) {
      props.handleProductSelected(product)
    } else {
      props.handleProductDeselected(product)
    }
  }, [checked, product.price])

  const handleCheckboxClick = (e) => {
    if(checked) {
      handlePriceChange({ target: { value: null } })
    } else {
      inputEl.current.focus()
    }
    setChecked(!checked)
  }

  const handlePriceChange = (e) => {
    const newProduct = {...product}

    newProduct.price = e.target.value || "0.0"

    setProduct(newProduct)
  }

  const handlePriceFieldExit = (e) => {
    if(parseFloat(e.target.value) >= 0 && !checked) {
      setChecked(true)
    }
  }

  const id = `parking_product_id_${product.id}`

  return (
    <div className="form-check" key={product.id}>
      <div className="check-part">
        <input
          className="form-check-input check_boxes optional"
          type="checkbox"
          value={product.id}
          name={`parking_product[${product.id}]`}
          id={id}
          onChange={handleCheckboxClick}
          checked={checked}
        />
        <label className="form-check-label collection_check_boxes" htmlFor={id}>
          {product.name}
        </label>
      </div>
      <div className={`price-part ${!checked && "not-selected"}`}>
        <input
          ref={inputEl}
          className="form-control string optional price-input"
          type="number"
          name={`parking_product_${product.id}`}
          value={product.price || '0.0'}
          onChange={handlePriceChange}
          onBlur={handlePriceFieldExit}
          min={0}
        />
        {props.currency}
      </div>
    </div>
  )
}

const ProductChecks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .form-check {
    margin-left: 0 !important;

    width: 32%;
    padding-bottom: 18%; /* 32:18, i.e. 16:9 */
    margin-bottom: 2%;
    justify-content: space-between;

    .price-input {
      margin-left: 5px;
      margin-right: 5px;
      max-width: 55px;
      height: 24px;
      padding: 5px;
    }

    .check-part, .price-part {
      display: flex;
      flex: 1;
    }

    .not-selected {
      color: #ccc;
      input {
        color: #888;
        background-color: #d2ddde;
        border-color: #d2ddde;
      }
    }
  }
`
