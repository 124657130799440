import { Controller } from 'stimulus'
import { t } from 'i18n'

export default class extends Controller {
  static targets = [
    'selectionForm', 'selectedProductId', 'selectedCarType', 'showCarProductsButton', 'submitFormButton', 'goBackButton', 'goBackToCarTypeListButton'
  ]
  static values  = {
    current:   String,
    productId: Number
  }

  submitForm(e) {
    e.preventDefault()

    if (this.selectedProductIdTarget.value) {
      this.selectionFormTarget.submit()
    } else {
      alert(t('site_payments.form.select_product'))
    }
  }

  selectProduct(e) {
    e.preventDefault()
    self = this
    this.clearActiveProducts(function() {
      self.productIdValue                = e.currentTarget.dataset.id
      self.selectedProductIdTarget.value = e.currentTarget.dataset.id
    })

    this.removeSelectedLabelClass()
    e.currentTarget.querySelector('.selection-item-label').classList.add('selection-item-label-selected')
  }

  clearActiveProducts(callback) {
    this.productIdValue                 = null
    this.selectedProductIdTarget.value  = null

    if (callback) callback()
  }

  setCarType(e) {
    e.preventDefault()

    const selectedGroup = e.currentTarget.dataset.group

    this.selectedCarTypeTarget.value = selectedGroup

    this.removeSelectedLabelClass()
    e.currentTarget.querySelector('.selection-item-label').classList.add('selection-item-label-selected')
  }

  showCarProducts(e) {
    e.preventDefault()

    if (!this.selectedCarTypeTarget.value) {
      alert(t('site_payments.form.select_car_type'))
      return
    }

    const products = document.querySelector(`[data-parent-group="${this.selectedCarTypeTarget.value}"]`)

    document.querySelectorAll('.js-car-selection-item').forEach((item, i) => {
      item.classList.add('hidden')
    })
    products.classList.toggle('hidden')

    this.toggleSubmitButtons()
    this.toggleBackButtons()
    this.toggleInfoBlockLabels()
  }

  goBackToCarTypeList(e) {
    this.removeSelectedLabelClass()

    document.querySelectorAll('.js-car-selection-item').forEach((item, i) => {
      item.classList.remove('hidden')
    })
    document.querySelectorAll('.js-car-product-list').forEach((item, i) => {
      item.classList.add('hidden')
    })

    this.clearActiveProducts()
    this.toggleSubmitButtons()
    this.toggleBackButtons()
    this.toggleInfoBlockLabels()
  }

  toggleBackButtons() {
    this.goBackButtonTarget.classList.toggle('hidden')
    this.goBackToCarTypeListButtonTarget.classList.toggle('hidden')
  }

  toggleSubmitButtons() {
    // Swap out "OK" buttons
    this.showCarProductsButtonTarget.classList.toggle('hidden')
    this.submitFormButtonTarget.classList.toggle('hidden')
  }

  toggleInfoBlockLabels() {
    document.querySelector('.js-service-info-block').classList.toggle('hidden')
    document.querySelector('.js-vehicle-info-block').classList.toggle('hidden')
  }

  removeSelectedLabelClass() {
    document.querySelectorAll('.selection-item-label-selected').forEach((item, i) => {
      item.classList.remove('selection-item-label-selected')
    })
  }
}
