// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

require('./stylesheets/application.scss')
require('@fortawesome/fontawesome-free/js/all')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// src/application.js
import { Application }            from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Clipboard from 'stimulus-clipboard'
import $ from 'jquery'
import 'select2'

const application = Application.start()
const context = require.context("../stimulus/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
application.register('clipboard', Clipboard)

// TODO: merge booking.js with main app pack
window.showModalAlert = function(contentsId, modalId, contents) {
  $(contentsId).html(contents)
  $(modalId).modal("show")
}

$(document).ready(function() {
  if (process.env.NODE_ENV == 'development') {
    window.jQuery = $
  }
  $('.select2-input').select2();
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import 'i18n/translations/en'
import 'i18n/translations/ru'
import 'i18n/translations/fr'
