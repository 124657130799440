import React from "react"
import { t } from 'i18n'

export default function FormField (props) {
  return (
    <div className="form-group string optional">
      <label className="string optional" htmlFor={props.fieldName}>
        {t(`activerecord.attributes.site.${props.fieldName}`)}
      </label>
      <input
        className="form-control string optional"
        type="text"
        name={`site[${props.fieldName}]`}
        id={props.fieldName}
        value={props.value}
        onChange={props.onChange}
      />
      {props.additionalText && (
        <div className="pl-2 font-weight-bold">{props.additionalText}</div>
      )}
    </div>
  )
}
