import React, { useState, useEffect } from 'react'
import PropTypes                      from 'prop-types'
import { t }                          from 'i18n'
import styled                         from 'styled-components'
import { orderBy }                    from 'lodash'

export default function CarWashProductGroup (props) {
  const groupNameTranslation = () => {
    const translation =  t(`activerecord.attributes.product.car_types.${props.groupName}`)

    return translation.includes('[missing') ? props.products[0].name.split(' - ')[0] : translation
  }

  const handlePriceChange = (product) => (e) => {
    const newProduct = {...product, price: e.target.value }

    props.onProductChange(newProduct)
  }

  const washTypeVisible = (washType) => {
    return props.selectedWashTypes.includes(washType)
  }

  const renderCarTypeImage = (product, alt) => {
    return (
      <ImageContainer>
        {
          product.car_type_image
          ? <img src={product.car_type_image} alt={alt} />
          : <div className="image-placeholder"></div>
        }
      </ImageContainer>
    )
  }

  return (
    <WashProductGroup>
      <div className="wash-group-header">
        {groupNameTranslation()}
      </div>
      {renderCarTypeImage(props.products[0], groupNameTranslation())}
      {props.products.filter((product) => !product.hidden).map((product, index) => {
        const selectedProduct = props.selectedProducts.find((p) => p.id === parseInt(product.id))

        return (
          <div className="wash-group-products" key={index}>
            <span className="wash-group-product-wash-type-char">
              {props.mappedWashTypes[product.wash_type]}
            </span>
            <span>
              <input
                className="form-control string optional price-input"
                type="number"
                name={`wash_product_${product.id}`}
                value={selectedProduct?.price || ''}
                onChange={handlePriceChange(product)}
                min={0}
                disabled={!washTypeVisible(product.wash_type)}
              />
            </span>
            <span>
              EUR
            </span>
          </div>
        )
      })}
    </WashProductGroup>
  )
}

CarWashProductGroup.propTypes = {
  mappedWashTypes:   PropTypes.object.isRequired,
  selectedWashTypes: PropTypes.array.isRequired,
  products:          PropTypes.array.isRequired,
  selectedProducts:  PropTypes.array.isRequired,
  groupName:         PropTypes.string.isRequired,
  onProductChange:   PropTypes.func.isRequired,
}

const WashProductGroup = styled.div`
  display: flex;
  flex: 0 0 144px;
  flex-direction: column;
  align-items: center;
  background-color: var(--grey);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;

  .wash-group-header {
    width: 100%;
    font-weight: bold;
    min-height: 40px;
    padding-left: 14px;
  }

  .wash-group-products {
    margin-bottom: 2px;
    width: 100%;

    span {
      display: inline-block;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }

    input {
      width: 75px;
      height: 24px;
      padding: 5px !important;
      background-color: #fff !important;
    }

    input:disabled {
      background-color: #d2ddde !important;
    }
  }
`

const ImageContainer = styled.div`
  img {
    max-width: 124px;
    max-height: 140px;
    margin-bottom: 10px;
  }

  .image-placeholder {
    max-width: 124px;
    max-height: 140px;
    margin-bottom: 10px;
  }
`
