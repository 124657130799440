import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'workingHours', 'workingHoursContainer',
    'monday',    'mondayStartAt',    'mondayEndAt',
    'tuesday',   'tuesdayStartAt',   'tuesdayEndAt',
    'wednesday', 'wednesdayStartAt', 'wednesdayEndAt',
    'thursday',  'thursdayStartAt',  'thursdayEndAt',
    'friday',    'fridayStartAt',    'fridayEndAt',
    'saturday',  'saturdayStartAt',  'saturdayEndAt',
    'sunday',    'sundayStartAt',    'sundayEndAt'
  ]

  toggleWorkingHours (e) {
    this.workingHoursContainerTarget.classList.toggle('hidden')
  }

  updateMonday() {
    this.mondayTarget.value = [this.mondayStartAtTarget.value, this.mondayEndAtTarget.value].join(',')
  }

  updateTuesday() {
    this.tuesdayTarget.value = [this.tuesdayStartAtTarget.value, this.tuesdayEndAtTarget.value].join(',')
  }

  updateWednesday() {
    this.wednesdayTarget.value = [this.wednesdayStartAtTarget.value, this.wednesdayEndAtTarget.value].join(',')
  }

  updateThursday() {
    this.thursdayTarget.value = [this.thursdayStartAtTarget.value, this.thursdayEndAtTarget.value].join(',')
  }

  updateFriday() {
    this.fridayTarget.value = [this.fridayStartAtTarget.value, this.fridayEndAtTarget.value].join(',')
  }

  updateSaturday() {
    this.saturdayTarget.value = [this.saturdayStartAtTarget.value, this.saturdayEndAtTarget.value].join(',')
  }

  updateSunday() {
    this.sundayTarget.value = [this.sundayStartAtTarget.value, this.sundayEndAtTarget.value].join(',')
  }
}
