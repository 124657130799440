import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
  ]

  static values  = {
    url: String,
    currentState: String,
    ignoredStates: Array,
  }

  initialize() {
    // console.log(this.urlValue)
    this.runWatcher()
  }

  runWatcher() {
    const url = this.urlValue;
    const currentState = this.currentStateValue;
    const ignoredStates = this.ignoredStatesValue;
    const interval = 3000;

    // console.log(`currentState: ${currentState}`)
    // console.log(`ignoredStates: ${ignoredStates}`)

    const doQuery = function() {
      var stopped;
      stopped = false;
      return $.ajax({
        url: url,
        cache: false,
        dataType: "json"
      }).done(function(result) {
        // console.log(result.state)
        if (result.state !== currentState && $.inArray(result.state, ignoredStates) === -1) {
          stopped = true;
          return window.location.reload();
        }
      }).always(function() {
        if (!stopped) {
          return setTimeout(doQuery, interval);
        }
      });
    };

    doQuery();
  }
}
