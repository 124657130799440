import { Controller } from 'stimulus'
import $ from 'jquery'
import axiosInstance from 'axios_instance'
import { t } from 'i18n'

export default class extends Controller {
  async save(e) {
    e.preventDefault()

    const form = $(this.element).find('form')

    const totalAssesments   = $('.question_answers').length
    let uncheckedAssesments = totalAssesments;

    $('.question_answers').each((index, el) => {
      const radios = $(el).find('input[type="radio"]')
      radios.each((index, el) => {
        if (el.checked) {
          uncheckedAssesments = uncheckedAssesments - 1
        }
      })
    })

    if (uncheckedAssesments > 0) {
      alert(t('parking_safety_assessment.select_all'));
      return
    }

    const dataDestination = '/parking_safety_assessment'

    try {
      const { data } = await axiosInstance.post(dataDestination, form.serialize())
      this.triggerPageUpdateEvent({ safetyLevel: data.safety_level, selectedAnswers: data.selected_answers })
      this.hideModal()
    } catch (error) {
      alert(t('parking_safety_assessment.assessment_error'), error)
    }
  }

  triggerPageUpdateEvent(data) {
    const outerFormControllerContainer = $('[data-controller="site-form"]')
    outerFormControllerContainer.trigger('modal::assessment-updated', data)
  }

  hideModal() {
    $(this.element).modal('hide')
  }
}
