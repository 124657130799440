import { Controller } from 'stimulus'
import axiosInstance from 'axios_instance'

export default class PartialReloadController extends Controller {
  static values = { url: String }

  connect() {
    window.addEventListener('modal-create', (e) => { reloadPartial(this.urlValue, this.element); }, { once: true})
    window.addEventListener('modal-update', (e) => { reloadPartial(this.urlValue, this.element); }, { once: true})
    window.addEventListener('advanced-search', (e) => { reloadAdvancedSearchPartial(this.urlValue, this.element); }, { once: true})
  }

  disconnect () {
    window.removeEventListener('modal-create', (e) => { reloadPartial(this.urlValue, this.element); }, { once: true})
    window.removeEventListener('modal-update', (e) => { reloadPartial(this.urlValue, this.element); }, { once: true})
    window.removeEventListener('advanced-search', (e) => { reloadAdvancedSearchPartial(this.urlValue, this.element); }, { once: true})
  }
}

function reloadPartial(url, element) {
  get(url).then(function(response) {
    element.insertAdjacentHTML('afterend', response.data)
    element.remove()
  }.bind(this)).catch(function(error) {
    console.log('partial reload error', {error: error})
  }.bind(this));
}

function reloadAdvancedSearchPartial(url, element) {
  const queryString = window.location.search;

  url = `${url}${queryString}`

  reloadPartial(url, element)
}

async function get(url) {
  const response = await axiosInstance.get(url)
  return response;
}
