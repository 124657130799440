import React from "react"
import { t } from 'i18n'

export default function CheckboxField (props) {
  return (
    <fieldset className={`form-group boolean optional ${props.additionalClass}`}>
      {props.fieldLegend}
      <div className="form-check">
        <input
          className="form-check-input boolean optional"
          type="checkbox"
          value={props.value}
          onChange={props.onChange}
          id={props.fieldName}
          checked={props.value == true}
          disabled={props.disabled}
        />
        <label className="form-check-label boolean optional" htmlFor={props.fieldName}>{props.label || t(`activerecord.attributes.site.${props.fieldName}`)}</label>
      </div>
    </fieldset>
  )
}
