import { Controller } from 'stimulus'
import axiosInstance from 'axios_instance'
import { t } from 'i18n'

export default class InPlaceEditableInputController extends Controller {
  static targets = ['input', 'inputContainer', 'valueContainer', 'editAction', 'acceptAction']

  initialize() {
    this.state = {
      visible:    false,
      updatePath: this.element.dataset.path,
      inputValue: this.inputTarget.value,
      elementId:  this.element.dataset.id,
      attribute:  this.element.dataset.attribute,
      triggerEvent: this.element.dataset.triggerEvent,
    }
  }

  connect() {
    this.inputTarget.addEventListener('click', (e) => { e.preventDefault(); e.stopPropagation(); })
    this.inputTarget.addEventListener('keyup', this.handleInputKeyUp.bind(this))
    this.inputTarget.addEventListener('change', this.handleInputChange.bind(this))
  }

  handleEditClick(e) {
    e.preventDefault()
    e.stopPropagation()

    this.state.visible = !this.state.visible

    this.toggleState()
    this.focusInput()
  }

  handleAcceptClick(e) {
    e.preventDefault()
    e.stopPropagation()

    this.state.visible = !this.state.visible

    this.toggleState()
  }

  async handleInputChange(e) {
    if (this.state.inputValue === this.inputTarget.value) return;

    try {
      this.toggleState()

      const { data } = await axiosInstance.put(this.state.updatePath, { invoice_number: e.target.value })

      if(data.status && data.status === 'in_progress') {
        alert(t('shared.still_processing'))
        this.resetInput()
      } else {
        this.updateValue(data[this.state.attribute])
        this.triggerStatusChecker()
      }
    } catch (error) {
      if (error.response.data.error) {
        alert(error.response.data.error)
        this.resetInput()
      }
    }
  }

  handleInputKeyUp(e) {
    if ((e.key === 'Enter' || e.key === 'Escape') && this.state.inputValue === this.inputTarget.value) {
      this.toggleState()
    }
  }

  resetInput() {
    this.updateValue(this.state.inputValue)
  }

  updateValue(value) {
    this.state.inputValue               = value
    this.valueContainerTarget.innerHTML = value
    this.inputTarget.value              = value
  }

  focusInput() {
    const end = this.state.inputValue.length

    this.inputTarget.setSelectionRange(end, end);
    this.inputTarget.focus()
  }

  toggleState() {
    this.inputContainerTarget.classList.toggle('hidden')
    this.editActionTarget.classList.toggle('hidden')
    this.acceptActionTarget.classList.toggle('hidden')
    this.valueContainerTarget.classList.toggle('hidden')
  }

  triggerStatusChecker() {
    if(!this.state.triggerEvent) {
      return
    }

    const event   = new Event(this.state.triggerEvent)
    const checker = document.getElementById(`${this.state.triggerEvent}-${this.state.elementId}`)
    checker.dispatchEvent(event)
  }
}
