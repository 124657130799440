import { Controller } from 'stimulus'
import $              from 'jquery'

export default class extends Controller {
  connect() {
    this.delegateEvents()
  }

  delegateEvents() {
    $(this.element).on('ajax:success', $.proxy(this.handleFormSubmit, this))
  }

  handleFormSubmit(e) {
    let [response, status] = e.originalEvent.detail

    if(response.success) {
      window.location.href = response.redirect_to
    } else {
      $("#new_payment_portal_deposit_wrapper").html(response.form_html)
    }
  }
}
