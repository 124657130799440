import MarkerClusterer from '@googlemaps/markerclustererplus';

export function createMap(element, options) {
  return new google.maps.Map(element, options);
}

export function createMarkerCluster(map, markers) {
  return new MarkerClusterer(map, markers, {
    styles: clusterStyles()
  });
}

export function clusterStyles() {
  var clusterInlineSvg = function () {
    var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43"><defs/><defs><path id="a" d="M0 .1h29.5v36H0z"/></defs><g fill="none" fill-rule="evenodd"><g transform="translate(7 1.6)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path fill="#18224B" d="M15.7 0h-2C6.2 0 0 6.3 0 13.9c0 8 7.9 17.2 12.2 21.7.4.4.8.6 1.4.6h2.3c.5 0 1-.2 1.4-.6 4.3-4.5 12.2-13.8 12.2-21.7C29.5 6.2 23.3 0 15.7 0" mask="url(#b)"/></g><path fill="#EA0029" d="M19.4 38.6H24c.6 0 1 .3 1.2.8l.3 1c.2.7-.4 1.5-1.2 1.5h-5.2c-.8 0-1.4-.8-1.1-1.5l.3-1c.1-.5.6-.8 1.1-.8"/></g></svg>');

    return ('data:image/svg+xml;base64,' + encoded);
  };

  return [
    {
        width: 43,
        height: 43,
        url: clusterInlineSvg(),
        textColor: 'white',
        textSize: 14
    }
  ];
}

export function createMarker(attributes) {
  if (attributes.lat == null || attributes.lng == null) {
    console.warn(point, 'Missing coordinates')
    return null
  }

  let marker =  new google.maps.Marker({
    position: {lat: attributes.lat, lng: attributes.lng}
  });

  marker.addListener('click', function() {
    window.dispatchEvent(new CustomEvent('marker-click', { detail: { marker: marker } }))
  });

  return marker
}
