import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'siteId', 'productId'
  ]

  siteChanged(e) {
    // update URL of product input
    const productInputSelect2Params = JSON.parse(this.productIdTarget.dataset.select2Params)
    const urlParts = productInputSelect2Params.url.split('/')
    urlParts[2] = this.siteIdTarget.value
    productInputSelect2Params.url = urlParts.join('/')

    this.productIdTarget.dataset.select2Params = JSON.stringify(productInputSelect2Params)

    // Reset products input
    $(this.productIdTarget).trigger('select2:select-by-outside-force', {})
  }
}
