import { Controller } from 'stimulus'
import $ from 'jquery'
import axiosInstance from 'axios_instance'
import axios, { CancelToken } from 'axios'

export default class extends Controller {
  initialize() {
  }

  async save(e) {
    e.preventDefault()

    const form = $(this.element).find('form')

    const dataDestination = '/partners.json'

    try {
      const { data } = await axiosInstance.post(dataDestination, form.serialize())
      this.triggerPageUpdateEvent({ text: data.name, id: data.id })
      this.hideModal()
    } catch (error) {
      alert('New record could not be created!', error)
    }
  }

  triggerPageUpdateEvent(data) {
    const outerFormControllerContainer = $('[data-controller="site-form"]')
    outerFormControllerContainer.trigger('modal::partner-created', data)
  }

  hideModal() {
    $(this.element).modal('hide')
  }
}
