import { Controller } from 'stimulus'
import axiosInstance  from 'axios_instance'

export default class extends Controller {
  static targets = [
    'iconCodeInput', 'iconPreview'
  ]

  connect() {
    this.iconCodeInputTarget.addEventListener('change', this.handleIconCodeInputChange.bind(this))
  }

  async handleIconCodeInputChange(e) {
    const dataDestination = `/services/render_svg_icon/${e.currentTarget.value}`

    try {
      const { data } = await axiosInstance.get(dataDestination)

      this.iconPreviewTarget.innerHTML = data

    } catch (error) {
      console.log(error)
    }
  }
}
