import { Controller } from 'stimulus'

export default class extends Controller {
  openColumnFilter(e) {
    e.preventDefault()

    const filterContainer = document.querySelector('[data-controller="table-column-filter"]')

    if (filterContainer) {
      filterContainer.classList.toggle('hidden')
    }
  }
}
