import React, { Fragment, useState, useEffect, useRef } from "react"
import { t } from 'i18n'

export default function CoordinatesFields (props) {
  const [previewVisible, setPreviewVisible] = useState(false)
  const mapRef = useRef()

  useEffect(() => {
    if(previewVisible) {
      showMapPreview()
    }
  }, [previewVisible])

  const showMapPreview = () => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'gmaps-iframe');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '400');
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('style', 'border:0');
    iframe.setAttribute('src', `https://www.google.com/maps/embed/v1/place?key=${props.apikeyValue}&q=${props.lat},${props.lng}`)
    iframe.setAttribute('allowfullscreen', false)

    mapRef.current.append(iframe)
  }

  const handlePreviewClick = (e) => {
    e.preventDefault()
    setPreviewVisible(!previewVisible)
  }

  return (
    <Fragment>
      <div className="form-group optional form-group-valid">
        <label className="float optional" htmlFor="lat">
          {t('sites.form.coordinates')}
        </label>
        <input
          type="text"
          id="lat"
          className="form-control string optional short-field"
          placeholder="Lat" onChange={props.onChangeLat}
          value={props.lat}
        />
        <input
          type="text"
          id="lng"
          className="form-control string optional short-field"
          placeholder="Lng"
          onChange={props.onChangeLng}
          value={props.lng}
        />
        <a
          className="btn btn-primary btn-preview"
          style={{ marginLeft: '5px' }}
          href="#"
          onClick={handlePreviewClick}
        >
          {previewVisible ? t('sites.form.close_preview') : t('sites.form.preview_on_map')}
        </a>
      </div>
      {previewVisible && <div ref={mapRef} className="coordinate-preview-container"></div>}
    </Fragment>
  )
}
