import React, { useState, useEffect, useMemo } from 'react'
import PropTypes                               from 'prop-types'
import { t }                                   from 'i18n'
import styled                                  from 'styled-components'
import { groupBy, orderBy, uniq }              from 'lodash'
import CarWashProductGroup                     from './CarWashProductGroup'

export default function CarWashProducts (props) {
  const [selectedProducts, setSelectedProducts]   = useState(props.selectedCarWashProducts)
  const [selectedWashTypes, setSelectedWashTypes] = useState(uniq(props.selectedCarWashProducts.map((p) => p.wash_type)))

  useEffect(() => {
    props.onChange(selectedProducts)
  }, [selectedProducts])

  useEffect(() => {
    removeProductsWithoutSelectedWashTypes()
  }, [selectedWashTypes])

  const setInitialWashTypes = () => {
    return uniq(props.selectedCarWashProducts.map((p) => p.wash_type))
  }

  const mappedWashTypes = useMemo(() => {
    const alpha    = Array.from(Array(26)).map((e, i) => i + 65)
    const alphabet = alpha.map((x) => String.fromCharCode(x))
    const map      = props.washTypes.map((type, index) => {
      return { [type[1]]: alphabet[index] }
    })

    return Object.assign({}, ...map)
  }, [])

  const handleWashTypeClick = (e) => {
    const washType = e.target.value
    let newWashTypes

    if (selectedWashTypes.includes(washType)) {
      newWashTypes = selectedWashTypes.filter(x => x !== e.target.value)
    } else {
      newWashTypes = [...selectedWashTypes, washType]
    }
    setSelectedWashTypes(newWashTypes)
  }

  const handleProductSelected = (product) => {
    const newSelectedProducts = [...selectedProducts].filter((p) => p.id !== product.id)

    if (parseFloat(product.price) >= 0) {
      newSelectedProducts.push(product)
    }

    setSelectedProducts(newSelectedProducts)
  }

  const removeProductsWithoutSelectedWashTypes = () => {
    const newSelectedProducts = [...selectedProducts].filter((p) => selectedWashTypes.includes(p.wash_type))
    setSelectedProducts(newSelectedProducts)
  }

  const groupedProducts = groupBy(props.carWashProducts, 'car_type')
  const orderedGroupedProducts = Object.entries(groupedProducts).sort(([a,],[b,]) => props.carWashSequence[a]-props.carWashSequence[b])

  const renderWashTypeCheckbox = (wt) => {
    return (
      <div className="form-check" key={wt[1]}>
        <input
          className="form-check-input check_boxes optional"
          type="checkbox"
          id={`wash-type-${wt[1]}`}
          value={wt[1]}
          onChange={handleWashTypeClick}
          checked={selectedWashTypes.includes(wt[1])}
        />
        <label className="form-check-label collection_check_boxes" htmlFor={`wash-type-${wt[1]}`}>
          {`(${mappedWashTypes[wt[1]]}) ${wt[0]}`}
        </label>
      </div>
    )
  }

  const renderWashProducts = () => {
    return (
      <WashProductContainer>
        {
          orderedGroupedProducts.map(([groupName,], i) => {
            const products = orderBy(groupedProducts[groupName], (prod) => {
              return mappedWashTypes[prod.wash_type]
            })

            return (
              <CarWashProductGroup
                key={i}
                mappedWashTypes={mappedWashTypes}
                selectedWashTypes={selectedWashTypes}
                products={products}
                selectedProducts={selectedProducts}
                groupName={groupName}
                onProductChange={handleProductSelected}
              />
            )
          })
        }
      </WashProductContainer>
    )
  }

  return (
    <div>
      <fieldset className="form-group check_boxes optional wash-type-filters">
        <legend className="col-form-label pt-0">{t('sites.form.programmes')}</legend>
        {props.washTypes.map((wt) => renderWashTypeCheckbox(wt))}
      </fieldset>
      <fieldset className="form-group check_boxes optional wash-products">
        <legend className="col-form-label pt-0">
          {t('sites.form.wash_prices')}
          <br />
          {t('sites.form.price_excl_vat')}
        </legend>
        {renderWashProducts()}
      </fieldset>
    </div>
  )
}

CarWashProducts.propTypes = {
  washTypes:               PropTypes.array.isRequired,
  carWashProducts:         PropTypes.array.isRequired,
  selectedCarWashProducts: PropTypes.array.isRequired,
  onChange:                PropTypes.func.isRequired,
}

const WashProductContainer = styled.div`
  display: flex;
  margin-left: 165px;
  flex-wrap: wrap;
`
