import { Controller } from 'stimulus'

const PARAM_NAME = 'sc'

export default class extends Controller {
  closeFilter(e) {
    e.preventDefault()
    this.element.classList.add('hidden')
  }

  submitFilter(e) {
    e.preventDefault()

    let url = new URL(window.location.href)
    // this won't work on IE
    url.searchParams.delete(PARAM_NAME)
    url.searchParams.append(PARAM_NAME, this.fetchSelectedColumns())

    window.location.replace(url)
  }

  fetchSelectedColumns() {
    const selectedColumns = []

    this.element.querySelectorAll('[type="checkbox"]').forEach((item, i) => {
      if(item.checked) {
        selectedColumns.push(item.value)
      }
    })

    return selectedColumns.join(',')
  }
}
