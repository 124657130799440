import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  handleReadClick(e) {
    $(".shown-desc-text").hide()
    $(".hidden-desc-text").show()
    $(".read-more-button").hide()
  }
}
