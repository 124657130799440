import { Controller } from 'stimulus'

export default class extends Controller {
  connect(e) {
    toggleCurrentlyActiveParent.bind(this)();

    document.addEventListener('click', handleGlobalCLick.bind(this))

    this.element.closest('.navigation').addEventListener('click', handleNavigationClick.bind(this))
  }
}

function handleNavigationClick(e) {
  const naviagtion = this.element.closest('.navigation')
  const nestedIsOpen = e.target.closest('.nested-nav-links.open') != null
  const nestedIsClosed = e.target.closest('.nested-nav-links') != null
  const naviagtionIsOpen = naviagtion.classList.contains('open')
  const singleElement = e.target.closest('.nested-nav-links')?.children?.length == 1

  if (singleElement) return

  if (naviagtionIsOpen && nestedIsOpen) return

  if (naviagtionIsOpen && nestedIsClosed) {
    e.preventDefault()
    closeAllNestedNavs()
    e.target.closest('.nested-nav-links').classList.add('open')
    return
  }

  if (naviagtionIsOpen) {
    naviagtion.classList.remove('open')
    removeFixedPositionFromScrollable()
    closeAllNestedNavs()
  } else {
    e.preventDefault()
    e.target.closest('.nested-nav-links')?.classList?.add('open')

    addFixedPositionToScrollable()
    naviagtion.classList.add('open')
  }
}

function toggleCurrentlyActiveParent() {
  this.element.querySelectorAll('.nested-nav-links').forEach(function (nested) {
    if (nested.querySelectorAll('.nav-item.active').length > 0) {
      nested.classList.add('active')
    } else {
      nested.classList.remove('active')
    }
  })
}

function addFixedPositionToScrollable() {
  document.querySelectorAll('.nested-nav-links.scrollable').forEach(function (nested) {
    setTimeout(function () {
      nested.classList.add('fixed-position-of-1st-element')
    }, 250)
  })
}

function closeAllNestedNavs() {
  document.querySelectorAll('.nested-nav-links').forEach(function (nested) {
    nested.classList.remove('open')
  })
}

function closeAllNavigations() {
  document.querySelectorAll('.navigation').forEach((navigation) => {
    navigation.classList.remove('open')
  })
}

function handleGlobalCLick(e) {
  if (e.target.closest('.navigation') != null) return

  removeFixedPositionFromScrollable()
  closeAllNavigations()
  closeAllNestedNavs()
}

function removeFixedPositionFromScrollable() {
  document.querySelectorAll('.nested-nav-links.scrollable').forEach(function (nested) {
    nested.classList.remove('fixed-position-of-1st-element')
  })
}
