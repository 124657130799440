import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'signIn', 'loading' ]

  connect() {
    this.handleLogin()
  }

  handleLogin() {
    this.loadingTarget.classList.toggle('hidden')
    this.signInTarget.click()
    this.signInTarget.classList.toggle('hidden')
  }
}
