import { Controller } from 'stimulus'
import $              from 'jquery'

export default class extends Controller {
  static targets = [
    'selectionForm', 'relativeDate', 'periodName', 'invoiceType'
  ]

  connect() {
    super.connect()
    this.checkRelativeDate()

    this.relativeDateTarget.addEventListener('change', this.checkRelativeDate.bind(this))
    this.periodNameTarget.addEventListener('change', this.checkRelativeDate.bind(this))
    this.invoiceTypeTarget.addEventListener('change', this.adjustSelectCollection.bind(this))
    this.adjustSelectCollection()
  }

  checkRelativeDate() {
    // possible values: month_half_1; month_half_2; month_full
    var selected_period = this.periodNameTarget.value

    var selected_date = new Date(this.relativeDateTarget.value)
    var current_date  = new Date()

    var selected_month = selected_date.getMonth() + 1
    var selected_year = selected_date.getFullYear()

    var current_year = current_date.getFullYear()
    var current_month = current_date.getMonth() + 1
    var current_day = current_date.getDate()

    if (current_year > selected_year) {
      $("#future-invoice-create").addClass("hidden")
      return
    }

    if ((selected_period == 'month_half_1' && current_month < selected_month) ||  ( selected_period == 'month_half_1' && current_day < 16  && current_month == selected_month)) {
      $("#future-invoice-create").removeClass("hidden")
      return
    }

    if ((selected_period == 'month_half_2' || selected_period == 'month_full') && current_month <= selected_month ) {
      $("#future-invoice-create").removeClass("hidden")
      return
    }

    $("#future-invoice-create").addClass("hidden")
  }

  adjustSelectCollection() {
    var selected_invoice_type =  this.invoiceTypeTarget.value

    if (selected_invoice_type == "partner") {
      $("#invoice-company-dropdown").prop("disabled", true);
      $("#invoice-partner-group-dropdown").prop("disabled", true);
      $("#invoice-partner-dropdown").prop("disabled", false);
    }

    if (selected_invoice_type ==  "company") {
      $("#invoice-company-dropdown").prop("disabled", false);
      $("#invoice-partner-group-dropdown").prop("disabled", true);
      $("#invoice-partner-dropdown").prop("disabled", true);
    }

    if (selected_invoice_type ==  "partner_group") {
      $("#invoice-company-dropdown").prop("disabled", true);
      $("#invoice-partner-group-dropdown").prop("disabled", false);
      $("#invoice-partner-dropdown").prop("disabled", true);
    }
  }
}
