import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['lat', 'lng', 'previewBtn']
  static values = {
    apikey: String,
    visible: Boolean
  }

  togglePreview(e) {
    e.preventDefault()
    const container = this.getPreviewContainer()

    if (!this.visibleValue) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'gmaps-iframe');
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '400');
      iframe.setAttribute('frameborder', 0);
      iframe.setAttribute('style', 'border:0');
      iframe.setAttribute('src', `https://www.google.com/maps/embed/v1/place?key=${this.apikeyValue}&q=${this.latTarget.value},${this.lngTarget.value}`)
      iframe.setAttribute('allowfullscreen', false)

      container.append(iframe)
      e.target.text = 'Close preview'
      this.visibleValue = true
    } else {
      const iframe = document.getElementById('gmaps-iframe')
      iframe.remove()

      e.target.text = 'Preview on map'
      this.visibleValue = false
    }
  }

  getPreviewContainer() {
    return document.getElementsByClassName('coordinate-preview-container')[0]
  }
}
