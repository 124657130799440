import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'partnerInput', 'safetyLevelInput', 'selectedParkingSafetyAnswersInput'
  ]

  connect() {
    this.delegateEvents()
    this.setSafetyLevelStars()
  }

  delegateEvents() {
    $(this.element).on('modal::partner-created', (e, data) => {
      $(this.partnerInputTarget).trigger('select2:select-by-outside-force', data)
    })

    $(this.element).on('modal::assessment-updated', (e, data) => {
      this.safetyLevelInputTarget.value = data.safetyLevel
      this.setSelectedAnswers(data.selectedAnswers)
      this.setSafetyLevelStars()
    })
  }

  setSelectedAnswers(selectedAnswers) {
    $(this.selectedParkingSafetyAnswersInputTarget).val(selectedAnswers);
    $(this.selectedParkingSafetyAnswersInputTarget).trigger('change');
  }

  setSafetyLevelStars() {
    const node = $('#safety_level_stars')
    node.empty()

    if (this.safetyLevelInputTarget.value == '') { return }

    const rating = document.createElement('div')
    rating.innerText = `${this.safetyLevelInputTarget.value}/5`
    rating.className = 'pt-1 pr-1'
    node.append(rating);

    var star = document.createElement('span')
    star.className = 'fa fa-star'
    node.append(star);

    $("#safety_level_stars:not([class*='mr-3'])").addClass('mr-3');
  }
}
