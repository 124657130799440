import React, { useState, useEffect } from "react"
import { t } from 'i18n'

export default function BaseServicesSelection (props) {
  const [selected, setSelected] = useState(props.selectedBaseServices)

  useEffect(() => {
    props.onChange(selected)
  }, [selected])

  const handleChange = (e) => {
    const type        = parseInt(e.target.value)
    const newSelected = [...selected]

    if(newSelected.indexOf(type) >= 0){
      newSelected.splice(newSelected.indexOf(type), 1)
    } else {
      newSelected.push(type)
    }

    setSelected(newSelected)
  }

  const renderBaseService = (bs) => {
    const bsId = `site_base_service_${bs.attributes.base_service_type}`

    return (
      <div className="form-check" key={bs.id}>
        <input
          className="form-check-input check_boxes optional"
          type="checkbox"
          value={bs.id}
          name="site[base_service_ids][]"
          id={bsId}
          onChange={handleChange}
          checked={selected.indexOf(parseInt(bs.id)) >= 0}
        />
        <label className="form-check-label collection_check_boxes" htmlFor={bsId}>{bs.attributes.name}</label>
      </div>
    )
  }

  return (
    <fieldset className="form-group check_boxes optional site_base_service_ids">
      <legend className="col-form-label pt-0">{t('sites.form.base_services')}</legend>
      {props.baseServices.map((bs) => renderBaseService(bs))}
    </fieldset>
  )
}
