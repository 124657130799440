import { Controller } from 'stimulus'
import $              from 'jquery'
import axiosInstance  from 'axios_instance'

export default class extends Controller {
  connect() {
    this.queryPath = this.element.dataset.queryPath
    this.interval  = 3000

    this.queryBackend()
  }

  queryBackend() {
    let stopped = false

    axiosInstance
      .get(this.queryPath)
      .then(({ data }) => {
        if (data.state == "completed" || data.state == "dropped") {
          stopped = true

          $(".loading-bar").hide()

          if(data.state == "completed") {
            $("#payment-completed-success").removeClass("hidden")
          } else {
            $("#payment-completed-error").removeClass("hidden")
          }
        }
      })
      .catch((error) => {
        alert(error)
      })
      .then(() => {
        if (!stopped) {
          setTimeout(this.queryBackend.bind(this), this.interval)
        }
      })
  }
}
