import React from "react"
import { t } from 'i18n'

export default function TextAreaField ({ fieldName, value, additionalText, onChange, maxLength }) {
  return (
    <div className="form-group string optional">
      <label className="string optional" htmlFor={fieldName}>
        {t(`activerecord.attributes.site.${fieldName}`)}
      </label>
      <textarea
        className="form-control string optional"
        name={`site[${fieldName}]`}
        id={fieldName}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
      />
      {additionalText && (
        <div className="pl-2 font-weight-bold">{additionalText}</div>
      )}
    </div>
  )
}
