import { Controller } from 'stimulus';
import axiosInstance  from 'axios_instance'
import $              from 'jquery'
import { t }          from 'i18n'

export default class extends Controller {
  static targets = [ 'agreement', 'agreed1', 'agreed2' ]

  connect() {
    this.singedAgreementId = this.element.dataset.singedAgreementId
    this.loadingElement = document.querySelector('.agreement-content .loading')

    this.agreementTarget.addEventListener('scroll', function() {
      this.toggleCheckbox(this.agreementTarget, this.agreed1Target);
      this.toggleCheckbox(this.agreementTarget, this.agreed2Target);
    }.bind(this));

    window.addEventListener('load', function(){
      this.toggleCheckbox(this.agreementTarget, this.agreed1Target);
      this.toggleCheckbox(this.agreementTarget, this.agreed2Target);
    }.bind(this));

    $(this.element.closest('.modal')).on('shown.bs.modal', function() {
      this.toggleCheckbox(this.agreementTarget, this.agreed1Target);
      this.toggleCheckbox(this.agreementTarget, this.agreed2Target);
    }.bind(this))

    this.element.addEventListener('load-translation', function(e) {
      console.log('load translation', e.detail);
      this.loadTranslationForLocale(e.detail);
    }.bind(this))
  }

  async loadTranslationForLocale(locale) {
    const agreementContainer = this.agreementTarget.querySelector(`.agreement-body-${locale}`)

    if(agreementContainer) {
      agreementContainer.classList.remove('hidden')
      this.hideOtherLocaleContainers(locale)

      return
    }

    this.showLoading()

    const dataDestination = `/signed_agreements/${this.singedAgreementId}/agreement_for_locale/${locale}`

    try {
      const { data } = await axiosInstance.get(dataDestination)

      const newContainer = this.createNewLocaleContainer(locale, data)

      this.agreementTarget.appendChild(newContainer)
      this.hideOtherLocaleContainers(locale)

      this.hideLoading()
    } catch (error) {
      alert(
        t('signed_agreements.errors.no_translation')
      )
      this.hideLoading()
    }
  }

  createNewLocaleContainer(locale, data) {
    const newDiv = document.createElement('div')
    newDiv.classList.add(`agreement-body-${locale}`)
    newDiv.innerHTML = data

    return newDiv
  }

  hideOtherLocaleContainers(locale) {
    document.querySelectorAll(`.agreement-body > :not(.agreement-body-${locale})`).forEach((e) => e.classList.add('hidden'))
  }

  showLoading() {
    this.loadingElement.classList.remove('hidden');
  }

  hideLoading() {
    this.loadingElement.classList.add('hidden');
  }

  toggleCheckbox(target, checkbox) {
    if (target.clientHeight != 0) {
      checkbox.disabled = !(Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) <= 1)
    }
  }
}
