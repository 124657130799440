import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['pageNumber']

  submitPage(e) {
    e.preventDefault()

    let url = new URL(window.location.href)
    url.searchParams.set('page', this.pageNumberTarget.value)
    window.location.replace(url)
  }
}
