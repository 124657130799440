import $ from 'jquery'
import { Controller } from 'stimulus'
import axiosInstance from 'axios_instance'
import { t } from 'i18n'

export default class extends Controller {
  static targets = [
    'baseServiceId', 'siteId', 'siteBaseServiceProductId',
    'driverId', 'vehicleId',
    'quantity', 'arrivingAt', 'arrivingAtDate', 'arrivingAtTime',
    'bookingPreview',
    'discountedCompanyPrice', 'companyTotal', 'companyVatAmount', 'companyTotalInclVat', // See: COMPARED_DISPLAYED_FIELDS
  ]

  initialize() {
    this.activeCompany = this.element.getAttribute('data-active-company-url');
    this.arrivingAtHour = document.getElementById('booking_arriving_at_time_4i');
    this.arrivingAtMinute = document.getElementById('booking_arriving_at_time_5i');
  }

  connect() {
    // Do not try to calculate or change anything if the form is in preview mode
    if (this.bookingPreviewTarget.value === 'true') return;

    if (this.siteIdTarget.value !== '') {
      this.handleSiteChange()
    }

    if (this.baseServiceIdTarget.value !== '') {
      this.getSiteCollection()
    }

    this.baseServiceIdTarget.addEventListener('change', this.handleBaseServiceChange.bind(this))
    this.siteIdTarget.addEventListener('change', this.handleSiteChange.bind(this))
    this.driverIdTarget.addEventListener('change', this.handleDriverChange.bind(this))

    this.baseServiceIdTarget.addEventListener('change', this.refreshAmounts.bind(this))
    this.siteIdTarget.addEventListener('change', this.refreshAmounts.bind(this))
    this.siteBaseServiceProductIdTarget.addEventListener('change', this.refreshAmounts.bind(this))

    this.arrivingAtTarget.addEventListener('change', this.refreshAmounts.bind(this))
    this.arrivingAtDateTarget.addEventListener('change', this.refreshAmounts.bind(this))
    this.arrivingAtHour.addEventListener('change', this.refreshAmounts.bind(this))
    this.arrivingAtMinute.addEventListener('change', this.refreshAmounts.bind(this))

    this.quantityTarget.addEventListener('change', this.refreshAmounts.bind(this))
  }

  async handleBaseServiceChange() {
    await this.getSiteCollection()
    await this.getProductCollection()
    await this.refreshAmounts()
    await this.handleSmartphoneAccessNotice()
  }

  async handleSiteChange() {
    await this.getBaseServiceCollection()
    await this.getProductCollection()
    await this.refreshAmounts()
    await this.handleSmartphoneAccessNotice()
  }

  async handleDriverChange() {
    await this.getVehicleCollection()
    await this.setVehicle()
  }

  arrivingAtValue() {
    if (!this.arrivingAtDateTarget.value) { return null }

    const newDate = new Date(this.arrivingAtDateTarget.value)
    newDate.setUTCHours(this.arrivingAtHour.value, this.arrivingAtMinute.value)

    return this.arrivingAtTarget.value = newDate.toISOString().slice(0, -1)
  }

  readFormState() {
    let formState = {}
    formState.base_service_id = parseInt(this.baseServiceIdTarget.value);
    formState.site_id = parseInt(this.siteIdTarget.value);
    formState.product_id = parseInt(this.siteBaseServiceProductIdTarget.value);
    formState.arriving_at = this.arrivingAtValue();
    formState.driver_id = parseInt(this.driverIdTarget.value);
    formState.vehicle_id = parseInt(this.vehicleIdTarget.value);
    formState.quantity = parseInt(this.quantityTarget.value);
    return formState;
  }

  isFormCompleted(formState) {
    return formState.base_service_id &&
      formState.site_id &&
      formState.product_id &&
      formState.arriving_at &&
      formState.driver_id &&
      formState.vehicle_id &&
      formState.quantity;
  }

  async refreshAmounts() {
    const formState = this.readFormState();
    this.clearAmounts();
    // console.log(formState)

    if (this.isFormCompleted(formState)) {
      // console.log("Completed.")
    } else {
      // console.log("NOT completed")
      return;
    }

    const active_company_prefix = this.activeCompany !== '' ? `/active_company/${this.activeCompany}` : '';
    const dataDestination = `${active_company_prefix}/bookings/booking_amounts/`;

    const params = {
      product_id: formState.product_id,
      quantity: formState.quantity,
      arriving_at: formState.arriving_at,
      driver_id: formState.driver_id,
    }

    try {
      const { data } = await axiosInstance.get(dataDestination, { params })
      if (JSON.stringify(formState) === JSON.stringify(this.readFormState())) {
        this.setAmounts(data);
      }
    } catch (error) {
      let message;
      if (error.response.data.message) {
        message = error.response.data.message
      } else {
        message = "Amounts could not be updated!"
      }

      if (JSON.stringify(formState) === JSON.stringify(readFormState())) {
        window.showModalAlert("#generic_error_modal_contents", "#generic_error_modal", message)
      }
    }
  }

  setAmounts(amount_data) {
    // See: COMPARED_DISPLAYED_FIELDS
    this.discountedCompanyPriceTarget.value = parseFloat(amount_data.discounted_company_price)
    this.companyTotalTarget.value = parseFloat(amount_data.company_total)
    this.companyTotalInclVatTarget.value = parseFloat(amount_data.company_total_incl_vat)
    this.companyVatAmountTarget.value = parseFloat(amount_data.company_vat_amount)
  }

  clearAmounts() {
    // See: COMPARED_DISPLAYED_FIELDS
    this.discountedCompanyPriceTarget.value =
      this.companyTotalTarget.value =
      this.companyTotalInclVatTarget.value =
      this.companyVatAmountTarget.value = ""
  }

  async setVehicle() {
    if (this.driverIdTarget.value === '') {
      this.vehicleIdTarget.value = ''
      $(this.vehicleIdTarget).trigger('change');
      return
    }

    const dataDestination = `/drivers/${this.driverIdTarget.value}/driver_vehicle`

    try {
      const { data } = await axiosInstance.get(dataDestination)
      this.vehicleIdTarget.value = data.vehicle_id || ''
      $(this.vehicleIdTarget).trigger('change');
    } catch (error) {
      alert('Driver data could not be retrieved!', error)
    }
  }

  async getVehicleCollection() {
    if (this.driverIdTarget.value === '') {
      this.updateCollection('booking_vehicle_id', this.vehicleIdTarget, [])
      return
    }

    // Update available vehicles based on driver
    const dataDestination = `/drivers/${this.driverIdTarget.value}/driver_company_vehicles`

    try {
      const { data } = await axiosInstance.get(dataDestination)
      this.updateCollection('booking_vehicle_id', this.vehicleIdTarget, data)
    } catch (error) {
      alert('Vehicle collection could not be updated!', error)
    }
  }

  async getSiteCollection() {
    // Update available sites based on base service
    const dataDestination = `/sites/base_service_sites`

    try {
      this.toggleDisabled(true)

      const params = {
        base_service_id: this.baseServiceIdTarget.value
      }
      const { data } = await axiosInstance.get(dataDestination, { params })

      this.updateCollection('booking_site_id', this.siteIdTarget, data)

      this.toggleDisabled(false)
    } catch (error) {
      alert('Site collection could not be updated!', error)
    }
  }

  async getProductCollection() {
    if (this.siteIdTarget.value === '' || this.baseServiceIdTarget.value === '') {
      this.updateCollection('booking_site_base_service_product_id', this.siteBaseServiceProductIdTarget, [])
      return
    }

    // Update available products based on site
    const dataDestination = `/sites/${parseInt(this.siteIdTarget.value)}/site_products`

    try {
      this.toggleDisabled(true)

      const params = {
        base_service_id: this.baseServiceIdTarget.value
      }
      const { data } = await axiosInstance.get(dataDestination, { params })

      this.updateCollection('booking_site_base_service_product_id', this.siteBaseServiceProductIdTarget, data)

      this.toggleDisabled(false)
    } catch (error) {
      alert('Product collection could not be updated!', error)
    }
  }

  async getBaseServiceCollection() {
    const dataDestination = `/sites/${parseInt(this.siteIdTarget.value)}/site_base_services`

    try {
      this.toggleDisabled(true)

      const { data } = await axiosInstance.get(dataDestination)

      this.updateCollection('booking_base_service_id', this.baseServiceIdTarget, data)

      this.toggleDisabled(false)
    } catch (error) {
      alert('Base service collection could not be updated!', error)
    }
  }

  async handleSmartphoneAccessNotice() {
    if (this.siteIdTarget.value === '' || this.baseServiceIdTarget.value === '') {
      this.toggleSmartphoneAccessNotice(false)
      return
    }

    // Check if site base service is smartphone access only
    const dataDestination = `/sites/${parseInt(this.siteIdTarget.value)}/smartphone_access_only`

    try {
      this.toggleDisabled(true)
      const params = {
        base_service_id: this.baseServiceIdTarget.value
      }
      const { data } = await axiosInstance.get(dataDestination, { params })
      this.toggleDisabled(false)

      this.toggleSmartphoneAccessNotice(data.smartphone_access_only)
    } catch (error) {
      alert('Could not retrieve site base service state!', error)
    }
  }

  toggleSmartphoneAccessNotice(show) {
    if (show === true) {
      $('.js-smartphone-access-notice').append(`<div>${t('bookings.form.smartphone_access_only')}</div>`)
    } else {
      $('.js-smartphone-access-notice').empty()
    }
  }

  updateCollection(selectId, selectField, data) {
    let initialOption = `<option value></option>`
    // If a value is selected and in collection array, then copy it over in options list
    if (selectField.value !== '' && data.find(dataRow => String(dataRow.id) === selectField.value)) {
      const selectedOption = $(`#${selectId} option[value=${selectField.value}]`)
      initialOption = `<option value=${selectedOption.val()}>${selectedOption.html()}</option>`
    }

    selectField.innerHTML = ''
    selectField.insertAdjacentHTML("beforeend", initialOption);
    data.forEach((dataRow) => {
      if (selectField.value !== String(dataRow.id)) {
        const elem = `<option value="${dataRow.id}">${dataRow.name}</option>`
        selectField.insertAdjacentHTML("beforeend", elem);
      }
    })
  }

  toggleDisabled(toggle) {
    this.baseServiceIdTarget.disabled = toggle
    this.siteIdTarget.disabled = toggle
    this.siteBaseServiceProductIdTarget.disabled = toggle
  }
}
