import { Controller } from 'stimulus'
import $ from 'jquery'
import axiosInstance from 'axios_instance'
import axios, { CancelToken } from 'axios'

export default class extends Controller {
  static targets = [
    'complaintsModal', 'transactionId', 'message'
  ]

  showModal(e) {
    e.preventDefault()

    const transactionId = e.target.closest('A').dataset.transactionId

    $(this.transactionIdTarget).val(transactionId)
    $(this.messageTarget).val('')

    $(this.complaintsModalTarget).modal('show')
  }

  async save(e) {
    e.preventDefault()

    const dataDestination = '/complaints.json'

    try {
      const { data } = await axiosInstance.post(dataDestination, { 'complaint': {
        transaction_id: $(this.transactionIdTarget).val(),
        message: $(this.messageTarget).val()
      } })
      this.hideModal()
    } catch (error) {
      alert('New record could not be created!', error)
    }
  }

  hideModal() {
    $(this.complaintsModalTarget).modal('hide')
  }
}
