import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'partnerPlaceholders',
    'companyPlaceholders',
    'intermediaryCompanyPlaceholders',
    'partnerLinks',
    'companyLinks',
    'intermediaryCompanyLinks'
  ]

  togglePlaceholderDescriptions (e) {
    const value = e.target.value

    if (value === 'company') {
      this.showCompany()
      this.hidePartner()
      this.hideIntermediaryCompany()
    }

    if (value === 'partner') {
      this.hideCompany()
      this.showPartner()
      this.hideIntermediaryCompany()
    }

    if (value === 'intermediary_company') {
      this.hideCompany()
      this.hidePartner()
      this.showIntermediaryCompany()
    }
  }

  showIntermediaryCompany() {
    this.intermediaryCompanyPlaceholdersTarget.classList.remove('hidden')
    this.intermediaryCompanyLinksTarget.classList.remove('hidden')
  }

  hideIntermediaryCompany() {
    this.intermediaryCompanyPlaceholdersTarget.classList.add('hidden')
    this.intermediaryCompanyLinksTarget.classList.add('hidden')
  }

  showCompany() {
    this.companyPlaceholdersTarget.classList.remove('hidden')
    this.companyLinksTarget.classList.remove('hidden')
  }

  hideCompany() {
    this.companyPlaceholdersTarget.classList.add('hidden')
    this.companyLinksTarget.classList.add('hidden')
  }

  showPartner() {
    this.partnerPlaceholdersTarget.classList.remove('hidden')
    this.partnerLinksTarget.classList.remove('hidden')
  }

  hidePartner() {
    this.partnerPlaceholdersTarget.classList.add('hidden')
    this.partnerLinksTarget.classList.add('hidden')
  }
}
