import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('tr[data-href]').forEach((el) => {
      const urlToFollow = el.dataset.href
      const target      = !!el.dataset.target

      el.addEventListener('reinitialize-with-new-url', this.reinitializeWithNewUrl.bind(this))

      el.querySelectorAll('td:not(.actions)').forEach((td, i) => {
        td.addEventListener('click', this.handleClick(target, urlToFollow).bind(this))
      })
    })
  }

  reinitializeWithNewUrl(e) {
    const el = e.target
    const target = !!el.dataset.target
    const newUrl = el.dataset.href

    el.querySelectorAll('td:not(.actions)').forEach((td, i) => {
      td.removeEventListener('click', this.handleClick(target, newUrl).bind(this))
    })

    el.querySelectorAll('td:not(.actions)').forEach((td, i) => {
      td.addEventListener('click', this.handleClick(target, newUrl).bind(this))
    })
  }

  handleClick(target, url) {
    return (e) => {
      if(target) {
        $(`<a href="${url}" target="${target}"></a>`)[0].click()
      } else {
        window.location.href = url
      }
    }
  }
}
