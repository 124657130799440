import { Controller } from 'stimulus'
import axiosInstance from 'axios_instance'

// Base code from:
// https://htmldom.dev/drag-and-drop-element-in-a-list/
// https://raw.githubusercontent.com/1milligram/html-dom/master/public/demo/drag-and-drop-element-in-a-list/index.html
// + stimulus/steero related corrections + taking scroll into account.

export default class extends Controller {
  static targets = [
    'list',
    'spinner',
  ]

  static values  = {
    sameSiteGroupId: String,
    dataDestination: String,
    removeUrl: String,
  }

  initialize() {
    const self = this;
    this.sameSiteGroupId = this.sameSiteGroupIdValue;

    this.draggingEle = null;
    this.placeholder = null;
    this.isDraggingStarted = false;

    // The current position of mouse relative to the dragging element
    this.x = null;
    this.y = null;

    this.listTarget.querySelectorAll('.osl-item .draggable').forEach(function (item) {
      item.addEventListener('mousedown', self.mouseDownHandler.bind(self));
    });
  }

  wrapperCard() {
    return this.listTarget.closest("div.card");
  }

  registerButton() {
    return this.wrapperCard().querySelector("a[data-role='register-btn']");
  }

  // Swap two nodes
  swap(nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    // Move `nodeB` to before the sibling of `nodeA`
    parentA.insertBefore(nodeB, siblingA);
  }

  // Check if `nodeA` is above `nodeB`
  isAbove(nodeA, nodeB) {
    // Get the bounding rectangle of nodes
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();

    return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
  }

  measureCardPosition() {
    const rect = this.wrapperCard().getBoundingClientRect();

    this.cardX = rect.left + window.scrollX;
    this.cardY = rect.top + window.scrollY;
  }

  mouseDownHandler(e) {
    if (this.stopStarted) return;
    if (e.button != 0) return;

    this.measureCardPosition();

    this.draggingEle = e.target.closest(".osl-item");
    this.boundMouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.boundMouseUpHandler = this.mouseUpHandler.bind(this);

    // Calculate current position of mouse relative to the dragging element
    const rect = this.draggingEle.getBoundingClientRect();
    this.x = e.pageX - rect.left - window.scrollX;
    this.y = e.pageY - rect.top - window.scrollY;

    // Attach the listeners to `document`
    document.addEventListener('mousemove', this.boundMouseMoveHandler);
    document.addEventListener('mouseup', this.boundMouseUpHandler);
  }

  mouseUpHandler() {
    if (this.stopStarted) return;

    this.stopDragging();
  }

  mouseMoveHandler(e) {
    if (this.stopStarted) return;

    const primaryMouseButtonDown = (e.buttons & 1) === 1;
    if (!primaryMouseButtonDown) {
      // sometimes MouseUp happens but event is not received
      this.stopDragging();
      return;
    }

    const draggingRect = this.draggingEle.getBoundingClientRect();

    if (!this.isDraggingStarted) {
        this.isDraggingStarted = true;
        this.orderBeforeDragging = this.calcOrderedSiteIds();

        // Let the placeholder take the height of dragging element
        // So the next element won't move up
        this.placeholder = document.createElement('div');
        this.placeholder.classList.add('osl-placeholder');
        this.draggingEle.parentNode.insertBefore(this.placeholder, this.draggingEle.nextSibling);
        this.draggingEle.style.width = `${draggingRect.width}px`;
        this.placeholder.style.height = `${draggingRect.height}px`;
    }

    // Set position for dragging element
    this.draggingEle.style.position = 'absolute';
    this.draggingEle.style.top = `${e.pageY - this.y - this.cardY}px`;
    this.draggingEle.style.left = `${e.pageX - this.x - this.cardX}px`;

    // The current order
    // prevEle
    // draggingEle
    // placeholder
    // nextEle
    const prevEle = this.draggingEle.previousElementSibling;
    const nextEle = this.placeholder.nextElementSibling;

    // The dragging element is above the previous element
    // User moves the dragging element to the top
    if (prevEle && this.isAbove(this.draggingEle, prevEle)) {
        // The current order    -> The new order
        // prevEle              -> placeholder
        // draggingEle          -> draggingEle
        // placeholder          -> prevEle
        this.swap(this.placeholder, this.draggingEle);
        this.swap(this.placeholder, prevEle);
        return;
    }

    // The dragging element is below the next element
    // User moves the dragging element to the bottom
    if (nextEle && this.isAbove(nextEle, this.draggingEle)) {
        // The current order    -> The new order
        // draggingEle          -> nextEle
        // placeholder          -> placeholder
        // nextEle              -> draggingEle
        this.swap(nextEle, this.placeholder);
        this.swap(nextEle, this.draggingEle);
    }
  }

  stopDragging() {
    this.stopStarted = true;

    // Remove the placeholder
    this.placeholder && this.placeholder.parentNode.removeChild(this.placeholder);

    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener('mousemove', this.boundMouseMoveHandler);
    document.removeEventListener('mouseup', this.boundMouseUpHandler);

    this.draggingEle.style.removeProperty('top');
    this.draggingEle.style.removeProperty('left');
    this.draggingEle.style.removeProperty('position');
    this.draggingEle.style.removeProperty('width');

    const orderBeforeDragging = this.orderBeforeDragging;

    this.x = null;
    this.y = null;
    this.draggingEle = null;
    this.isDraggingStarted = false;
    this.orderBeforeDragging = null;
    this.placeholder = null;
    this.boundMouseMoveHandler = null;
    this.boundMouseUpHandler = null;

    this.saveNewPositions(orderBeforeDragging); // stopStarted cleared here
  }

  calcOrderedSiteIds() {
    return Array.from(this.listTarget.querySelectorAll('.osl-item')).map(function(el) {
      return el.getAttribute("data-site-id") * 1;
    });
  }

  refreshResourceUrls() {
    this.listTarget.querySelectorAll(".osl-item a[data-method='delete']").forEach((linkEl) => {
      const siteId = linkEl.closest(".osl-item").getAttribute("data-site-id") * 1;
      const params = `?parent_site_ssg_id=${this.sameSiteGroupId}&site_to_remove_id=${siteId}`;
      linkEl.setAttribute("href", this.removeUrlValue + params);
    });

    const registerButton = this.registerButton();
    const registerUrlValue = registerButton.getAttribute("data-url");
    const registerParams = `?parent_site_ssg_id=${this.sameSiteGroupId}`;
    registerButton.setAttribute("href", registerUrlValue + registerParams);
  }

  saveNewPositions(orderBeforeDragging) {
    const newSiteOrder = this.calcOrderedSiteIds();

    const finalizeDrop = () => {
      this.spinnerTarget.style.visibility = 'hidden';
      this.refreshResourceUrls();
      this.stopStarted = null;
    };

    if (orderBeforeDragging.toString() == newSiteOrder.toString()) {
      console.log("Save skipped, order was not changed.");
      finalizeDrop();
      return;
    }

    const postData = { site_ids: newSiteOrder, same_site_group_id: this.sameSiteGroupId }

    this.spinnerTarget.style.removeProperty('visibility');
    axiosInstance.post(this.dataDestinationValue, postData)
      .then(response => {
        console.log(`Saved. New ssgId=${response.data.same_site_group_id}`);
        this.sameSiteGroupId = response.data.same_site_group_id;
      })
      .catch(error => {
        window.last_error = error;
        alert(error.response.data.text); // TODO: modal
        console.error('There was an error!', error);
      })
      .finally(() => {
        finalizeDrop();
      });
  }

}
