import { Controller } from 'stimulus'
import axios from 'axios'
import { createMap } from '../../src/map_helpers'
import { updateSearchParams } from 'src/helpers'
import $ from 'jquery'
import markerIcon from '../../images/map-marker.svg'

export default class extends Controller {
  initialize() {
    this.center = { lat: 56.976237422126, lng: 24.167152619166522 }
    this.zoom = 8
    this.markers = []
    this.infoWindows = []
    this.sitesPath = '/partner_site_maps/sites'
    this.markerInfoWindowTemplatePath = '/partner_site_maps/info_window_template'
    this.activeCompany = this.element.getAttribute('data-active-company-url');
    this.map_id = 'partner_site_map'
    this.markerIconSize = 32
  }

  connect() {
    this.map = createMap(document.getElementById(this.map_id), {
      center: this.center,
      zoom: this.zoom
    })
    this.renderMapSites()

    const self = this

    if (this.map) {
      this.map.addListener('click', (e) => {
        self.closeInfoWindows()
      })
    }
  }

  closeInfoWindows() {
    for (let i = 0; i < this.infoWindows.length; i++ ) {
      this.infoWindows[i].close()
    }
    this.infoWindows = []
  }

  renderMapSites() {
    this.getSites()
      .then(function (response) {
        this.addSitesToMap(response.data)
      }.bind(this));
  }

  removeMarkersFromMap() {
    this.markers.forEach(function(marker) { marker.setMap(null); });
    this.markers = []
  }

  updateMap(e) {
    e.preventDefault()
    this.removeMarkersFromMap()
    updateSearchParams(e.target.parentElement)
    this.renderMapSites()
  }

  createMap() {
    return new google.maps.Map(document.getElementById(this.map_id), {
      center: this.center,
      zoom: this.zoom
    })
  }

  async getSites() {
    const url = `${this.sitesPath}/${window.location.search}`

    return await axios.get(url)
  }

  addSitesToMap(sites) {
    sites.data.forEach(function(site) {
      if (site.attributes.lat == null || site.attributes.lng == null) {
        console.warn(site, 'Missing coordinates')
        return
      }

      const marker = this.createMarker(site.attributes);
      marker.site_id = site.id

      this.markers.push(marker)
      marker.setMap(this.map)
      this.addMarkerInfoWindow(this.map, marker)
    }.bind(this))
  }

  createMarker(attributes) {
    return new google.maps.Marker({
      position: {lat: attributes.lat, lng: attributes.lng},
      title: attributes.partner_name,
      icon: {
        url: markerIcon,
        scaledSize: new google.maps.Size(this.markerIconSize, this.markerIconSize)
      }
    })
  }

  addMarkerInfoWindow(map, marker) {
    const infoWindow = new google.maps.InfoWindow()

    marker.addListener('click', function() {
      this.getMarkerInfoWindowTemplate(marker.site_id)
        .then(function (response) {
          this.closeInfoWindows()

          infoWindow.setContent(response.data)
          infoWindow.open(map, marker)

          this.infoWindows.push(infoWindow)
        }.bind(this))
    }.bind(this))

    google.maps.event.addListener(infoWindow, 'domready', function() {
      document.querySelector('button.close').addEventListener('click', function() {
        infoWindow.close()
      })

      const tooltipItems = document.querySelectorAll('.js-tooltip-item')
      tooltipItems.forEach(function(currentItem){
        currentItem.addEventListener('mouseover', function() {
          $(currentItem).tooltip('show')
        })
      })
    })
  }

  async getMarkerInfoWindowTemplate(site_id) {
    const active_company_prefix = this.activeCompany !== '' ? `/active_company/${this.activeCompany}` : '';
    const url = `${active_company_prefix}${this.markerInfoWindowTemplatePath}/${site_id}`;

    return await axios.get(url)
  }
}
