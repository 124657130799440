import { Controller } from 'stimulus'
import $ from 'jquery'
import 'bootstrap-datepicker'

export default class extends Controller {
  connect() {
    $(this.element).datepicker(
      {
        format: 'yyyy-mm-dd',
        autoclose: true,
        todayHighlight: true,
        weekStart: 1,
        todayBtn: true,
      }
    ).on('changeDate', function(e) {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }
}
