import { Controller } from 'stimulus'
import {
  getData as index,
  getData as advancedSearch,
  getData as show,
  getData as new_,
  postData as create,
  getData as edit,
  postData as update
} from '../../src/restful_action_helpers'
import $ from 'jquery'
import { updateSearchParams } from 'src/helpers'

export default class ModalFormController extends Controller {
  connect() {
    multipleModalFadeZIndexFix()

    if (this.element.classList.contains('modal')) {
      $(this.element).modal('show')
    }
  }
}

function multipleModalFadeZIndexFix() {
  $(document).on('show.bs.modal', '.modal', function () {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
  });
}

ModalFormController.prototype.index = function(e) {
  e.preventDefault()

  index(e.target.href).then(function(response) {
      document.querySelector('body').insertAdjacentHTML('beforeend',response.data)
    }.bind(this)).catch(function(error) {
      console.log('modal index error', {error: error})
    }.bind(this));
}

ModalFormController.prototype.advanced_search = function(e) {
  e.preventDefault()

  if (e.target.classList.contains('disabled')) return
  e.target.classList.add('disabled')

  advancedSearch(e.target.closest('a').href).then(function(response) {
      document.querySelector('body').insertAdjacentHTML('beforeend',response.data)
    }.bind(this)).catch(function(error) {
      console.log('modal advanced_search error', {error: error})
    }.bind(this)).finally(function(f) {
      e.target.classList.remove('disabled')
    }.bind(this));
}

ModalFormController.prototype.search = function(e) {
  e.preventDefault()

  updateSearchParams($(this.element).find('form')[0])

  window.dispatchEvent(new CustomEvent('advanced-search'))
}

ModalFormController.prototype.show = function(e) {
  e.preventDefault()
  show(url)
};

ModalFormController.prototype.new = function(e) {
  e.preventDefault()
  if (e.target.classList.contains('disabled')) return
  e.target.classList.add('disabled')

  let href = (e.target.tagName == 'A' ? e.target.href : e.target.closest('a').href)

  new_(href)
    .then(function(response) {
      document.querySelector('body').insertAdjacentHTML('beforeend', response.data)
    }.bind(this)).catch(function(error) {
      console.log('modal new error', {error: error})
    }.bind(this)).finally(function(f) {
      e.target.classList.remove('disabled')
    }.bind(this));
}

ModalFormController.prototype.create = function(e) {
  e.preventDefault();

  if (e.currentTarget.classList.contains('disabled')) { return }

  const modalAfterAction = this.element.dataset.afterAction

  e.currentTarget.classList.add('disabled')

  $(this.element).on('hidden.bs.modal', function(e) {
    this.element.remove()

    create($(this.element).find('form')).then(function(response) {
      if(modalAfterAction !== 'dont-replace-content') {
        document.querySelector('body').insertAdjacentHTML('beforeend', response.data)
      }

      window.dispatchEvent(new CustomEvent('modal-create'))
    }.bind(this)).catch(function(error) {
      console.log('modal create error', {error: error})
    }.bind(this));
  }.bind(this))

  $(this.element).modal('hide');
}

ModalFormController.prototype.create_remote_booking = ModalFormController.prototype.create

ModalFormController.prototype.edit = function(e) {
  e.preventDefault()

  let href = (e.target.tagName == 'A' ? e.target.href : e.target.closest('a').href)

  edit(href).then(function(response) {
      document.querySelector('body').insertAdjacentHTML('beforeend', response.data)
    }.bind(this)).catch(function(error) {
      console.log('modal edit error', {error: error})
    }.bind(this));
}

ModalFormController.prototype.update = function(e) {
  e.preventDefault()
  if (e.currentTarget.classList.contains('disabled')) { return }
  e.currentTarget.classList.add('disabled')

  $(this.element).on('hidden.bs.modal', function(e) {
    this.element.remove()

    update($(this.element).find('form')).then(function(response) {
        document.querySelector('body').insertAdjacentHTML('beforeend', response.data)

        window.dispatchEvent(new CustomEvent('modal-update'))
      }.bind(this)).catch(function(error) {
        console.log('modal update error', {error: error})
      }.bind(this));
  }.bind(this))

  $(this.element).modal('hide');
}
