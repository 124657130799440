import React, { useEffect, useState }       from 'react'
import { t }  from 'i18n'
import Select from 'react-select'

export default function Select2Field (props) {
  const [data, setData] = useState(props.data.map((i) => { return { value: i[1], label: i[0] } }))

  const handleChange = (e) => {
    props.onChange(e)
  }

  const renderRequired = () => {
    return props.required ? <abbr title="required">*</abbr> : ''
  }

  const renderError = () => {
    return props.errorMessage ? <div className='invalid-feedback'>{props.errorMessage}</div> : ''
  }

  return (
    <div className={`form-group string optional ${props.fieldName}`}>
      <label className="string optional" htmlFor={props.fieldName}>
        {t(`activerecord.attributes.site.${props.fieldName}`)}
        {renderRequired()}
      </label>
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        options={data}
        value={props.value}
        onChange={handleChange}
      />
    {renderError()}
    </div>
  )
}
