import { Controller } from 'stimulus'
import $ from 'jquery'
import 'bootstrap-datepicker'
import { updateSearchParams } from 'src/helpers'

export default class extends Controller {
  connect() {
    const skipParams = this.element.dataset.skipParams

    $(this.element).datepicker(
      {
        format: this.element.dataset.format,
        autoclose: true,
        todayHighlight: true,
        weekStart: 1,
        todayBtn: true
      }
    ).on('changeDate', function(e) {
      updateSearchParams(e.currentTarget.closest('form'), skipParams)

      window.dispatchEvent(new CustomEvent('advanced-search'))
    });
  }
}
