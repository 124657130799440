import axios from 'axios'

const axiosInstance = axios.create()

const requestHandler = (request) => {
  const metaTag = document.head.querySelector('[name="csrf-token"][content]')
  request.headers['X-CSRF-TOKEN'] = metaTag ? metaTag.content : 'no-csrf-token-specified'
  return request
}

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)

export default axiosInstance
