import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  defautlOptions() {
    return {
      placeholder: this.placeholder || "Select value",
      allowClear: this.allowClear || false,
      ...this.tags
    }
  }

  connect() {
    let options = this.defautlOptions()

    if(this.url) {
      options = Object.assign(options, this.remoteCallOptions())
    }
    $(this.element).select2(options)

    if(this.initialValue) {
      if (Array.isArray(this.initialValue)) {
        this.initialValue.forEach(data => {
          $(this.element).append(this.createNewOption(data)).trigger('change')
        })
      } else {
        $(this.element).append(this.createNewOption(this.initialValue)).trigger('change')
      }
    }

    this.delegateEvents()
  }

  delegateEvents() {
    $(this.element).on('select2:select-by-outside-force', (e, data) => {
      const option = new Option(data.text, data.id, true, true)
      $(this.element).append(option).trigger('change')
    })

    $(this.element).on('select2:select', function (e) {
      const event = new Event('change', { bubbles: true })
      event.originalEvent = e
      this.dispatchEvent(event)
    })

    $(this.element).on('select2:unselect', function (e) {
      const event = new Event('change', { bubbles: true })
      event.originalEvent = e
      this.dispatchEvent(event)
    })
  }

  createNewOption(data) {
    return new Option(data.text, data.id, true, true)
  }

  remoteCallOptions() {
    const self = this

    return {
      ajax: {
        url: function() {
          return self.remoteURL()
        },
        dataType: 'json',
        data: function (params) {
          const query = {
            q:    params.term,
            page: params.page || 1
          }
          return query
        },
        processResults: function (data) {
          let simplified;

          if(data.data) {
            simplified = data.data.map(function(item) {
              return item.attributes
            })
          } else {
            simplified = data
          }

          return {
            results: simplified.map(function(item) {
              return { id: item.id, text: item[self.resultTextField], sourceItem: item }
            })
          }
        }
      },
      allowClear: true,
      minimumInputLength: 3,
    }
  }

  remoteURL() {
    return this.url
  }

  get dataset() {
    const select2Data = this.element.dataset.select2Params
    return select2Data ? JSON.parse(select2Data) : {}
  }

  get initialValue() {
    return this.dataset.initialValue
  }

  get placeholder() {
    return this.dataset.placeholder
  }

  get tags() {
    let tags = this.element.dataset.tags || false
    let tagOptions = {}

    if (tags) {
      tagOptions = {
        createTag: function (params) {
          return {
            id: params.term,
            text: params.term,
            newOption: true
          }
        },
        templateResult: function (data) {
          var $result = $("<span></span>");

          $result.text(data.text);

          if (data.newOption) {
            $result.append(" <em>(new)</em>");
          }

          return $result;
        }
      }
    }

    return {
      tags: tags,
      ...tagOptions,
    }
  }

  get allowClear() {
    return this.dataset.allow_clear
  }

  get url() {
    return this.dataset.url
  }

  get resultTextField() {
    return this.dataset.result_text_field || 'name'
  }
}
