import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'languageSelect' ]

  connect() {
    this.form = document.querySelector('[data-controller="signed-agreement-form"]')

    this.delegateEvents()
  }

  delegateEvents() {
    this.languageSelectTarget.addEventListener('change', function(e){
        const event = new CustomEvent('load-translation', { detail: e.target.value });

        this.form.dispatchEvent(event);
      }.bind(this)
    )
  }
}
