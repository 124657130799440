import { Controller } from 'stimulus'
import axios from 'axios'
import { createMap, createMarkerCluster, createMarker } from '../../src/map_helpers'
import { getData } from '../../src/restful_action_helpers'

export default class MapController extends Controller {
  static values = {
    url: String,
    center: Object,
    zoom: Number
  }

  initialize() {
    this.markers = []
    this.infoWindow = new google.maps.InfoWindow();
  }

  connect() {
    window.addEventListener('marker-click', (e) => { this.handleMarkerClick(e) })

    this.map = createMap(this.element, {
      center: this.centerValue,
      zoom: this.zoomValue
    })

    this.getPoints()
      .then(function (response) {
        this.addPointsToMap(response.data)
        this.markerCluster = createMarkerCluster(this.map, this.markers)
      }.bind(this));
  }

  disconnect () {
    window.removeEventListener('marker-click', (e) => { this.handleMarkerClick(e) })
  }

  async getPoints() {
    const url = this.urlValue

    return await axios.get(url);
  };

  addPointsToMap(points) {
    points.data.forEach(function(point) {
      const marker = createMarker(point.attributes);
      this.markers.push(marker);
      marker.resource_id = point.id;
      marker.setMap(this.map);
    }.bind(this))
  }
}

MapController.prototype.handleMarkerClick = function(e) {
  (async () => {
    const id = e.detail.marker.resource_id
    var url = `/parking_areas/${id}?layout=info_window`
    const result = await getData(url)

    this.infoWindow.close();
    this.infoWindow.setContent(result.data)
    this.infoWindow.open(this.map, e.detail.marker);
  })()
}
