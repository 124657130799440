import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'role', 'partnerId', 'siteId', 'companyId', 'partnerGroupId', 'intermediaryId', 'sendInvitation', 'passwordFieldsContainer',
    'passwordField', 'passwordConfirmationField'
  ]

  roleChanged (e) {
    switch (e.target.value) {
      case 'intermediary_admin':
        this.intermediaryParent.classList.remove('hidden')

        this.partnerGroupParent.classList.add('hidden')
        this.partnerParent.classList.add('hidden')
        this.siteParent.classList.add('hidden')
        this.companyParent.classList.add('hidden')
        break
      case 'partner_group_manager':
        this.partnerGroupParent.classList.remove('hidden')

        this.partnerParent.classList.add('hidden')
        this.siteParent.classList.add('hidden')
        this.companyParent.classList.add('hidden')
        this.intermediaryParent.classList.add('hidden')
        break
      case 'partner_admin':
      case 'partner_user':
        this.partnerParent.classList.remove('hidden')

        this.partnerGroupParent.classList.add('hidden')
        this.siteParent.classList.add('hidden')
        this.companyParent.classList.add('hidden')
        this.intermediaryParent.classList.add('hidden')
        break
      case 'site_admin':
        this.siteParent.classList.remove('hidden')

        this.partnerParent.classList.add('hidden')
        this.partnerGroupParent.classList.add('hidden')
        this.companyParent.classList.add('hidden')
        this.intermediaryParent.classList.add('hidden')
        break
      case 'company_admin':
      case 'company_user':
        this.companyParent.classList.remove('hidden')
        this.fixCompanyParentInputWitdh()

        this.partnerParent.classList.add('hidden')
        this.partnerGroupParent.classList.add('hidden')
        this.siteParent.classList.add('hidden')
        this.intermediaryParent.classList.add('hidden')
        break
      default:
        this.companyParent.classList.add('hidden')
        this.partnerParent.classList.add('hidden')
        this.partnerGroupParent.classList.add('hidden')
        this.siteParent.classList.add('hidden')
        this.intermediaryParent.classList.add('hidden')
    }
  }

  toggleSendInvitation (e) {
    this.passwordFieldsContainerTarget.classList.toggle('hidden')
    this.passwordFieldTarget.disabled = !this.passwordFieldTarget.disabled
    this.passwordConfirmationFieldTarget.disabled = !this.passwordConfirmationFieldTarget.disabled
  }

  fixCompanyParentInputWitdh () {
    // In case of multiple select2 element, when it is hidden at first, then on show, input is not resized properly. Doing it manually here.
    const input = this.companyParent.getElementsByClassName('select2-search__field')[0]
    input.style.width = '100%'
  }

  get partnerGroupParent() {
    return this.partnerGroupIdTarget.parentElement
  }

  get partnerParent() {
    return this.partnerIdTarget.parentElement
  }

  get siteParent() {
    return this.siteIdTarget.parentElement
  }

  get companyParent() {
    return this.companyIdTarget.parentElement
  }

  get intermediaryParent() {
    return this.intermediaryIdTarget.parentElement
  }
}
