import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    'vehicleId', 'driverPlateNumber', 'vehicleIdWrapper',
  ]

  driverIdChanged(e) {
    let plate = null
    if (e.originalEvent && e.originalEvent.params) {
      // https://select2.org/programmatic-control/events#event-data
      if (e.originalEvent.params.data.selected) {
        plate = e.originalEvent.params.data.sourceItem.
          vehicle_registration_plate_number
      }
    }

    this.driverPlateNumberTarget.value = plate
    $(this.vehicleIdTarget).val(null).trigger('change')
  }

  toggleVehiclePicker(e) {
    this.vehicleIdWrapperTarget.classList.toggle('hidden')
  }

}
