import axiosInstance from 'axios_instance'

export async function getData(url) {
  const response = await axiosInstance.get(url)
  return response;
}

export async function postData(form) {
  const token = document.querySelector('[name=csrf-token]')?.content
  axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = token

  var url = new URL(form[0].action);
  url.searchParams.set('layout', 'modal');

  const response = await axiosInstance.post(url.toString(), form.serialize())
  return response;
}
