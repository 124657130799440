import React, { Fragment, useState, useEffect, useMemo, useRef } from "react"
import BaseServicesSelection  from './BaseServicesSelection'
import ServicesSelection      from './ServicesSelection'
import CoordinatesFields      from './CoordinatesFields'
import FormField              from './FormField'
import CheckboxField          from './CheckboxField'
import TextAreaField          from './TextAreaField'
import ParkingProducts        from './ParkingProducts'
import CarWashProducts        from './CarWashProducts'
import axiosInstance          from 'axios_instance'
import axios, { CancelToken } from 'axios'
import Select2Field           from './Select2Field'
import WorkingHours           from './WorkingHours'
import $                      from 'jquery'

import { t }                  from 'i18n'
import { isEmpty }            from "lodash"

export default function SiteProductManager (props) {
  const [baseServices, setBaseServices]       = useState(JSON.parse(props.base_services).data)
  const [services, setServices]               = useState(JSON.parse(props.services).data)
  const [parkingProducts, setParkingProducts] = useState(JSON.parse(props.parking_products).data.map((pp) => pp.attributes))
  const [carWashProducts, setCarWashProducts] = useState(JSON.parse(props.car_wash_products).data.map((pp) => pp.attributes))
  const [state, setState]                     = useState(JSON.parse(props.site).data.attributes)
  const [paymentMethods, setPaymentMethods]   = useState(props.payment_methods)
  const [countries, setCountries]             = useState(props.countries)
  const googleApiKey                          = props.apikey_value
  const partnerInputRef                       = useRef()
  const safetyLevelInputRef                   = useRef()
  const selectedParkingSafetyAnswersInputRef  = useRef()
  const [errors, setErrors]                   = useState({})
  const emptyWorkingHours = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }
  const [siteBaseServiceState, setSiteBaseServiceState] = useState({
    parking: { working_hours: emptyWorkingHours, working_247: false, discount_value: null },
    truckWash: { working_hours: emptyWorkingHours, working_247: false, discount_value: null }
  })

  useEffect(() => {
    partnerInputRef.current = document.getElementsByName('site[partner_id]')[0]
    safetyLevelInputRef.current = document.getElementsByName('site[safety_level]')[0]
    selectedParkingSafetyAnswersInputRef.current = document.getElementsByName('site[selected_parking_safety_answers][]')[1]

    const parkingState = {...state.site_base_services.filter(sbs => sbs.base_service_id === parseInt(parkingBaseService.id))[0]}

    const truckWashState = {...state.site_base_services.filter(sbs => sbs.base_service_id === parseInt(carWashBaseService.id))[0]}

    setSiteBaseServiceState({
      parking: isEmpty(parkingState) ? { ...siteBaseServiceState.parking } : parkingState,
      truckWash: isEmpty(truckWashState) ? { ...siteBaseServiceState.truckWash } : truckWashState
    })
  }, [])

  useEffect(() => {
    const node = $('.form-group.site_partner')
    node.children('.invalid-feedback').remove();
    if (errors['partner_id']) {
      const errorMessage = document.createElement('div')
      errorMessage.className = 'invalid-feedback'
      errorMessage.innerText = errors['partner_id']
      node.append(errorMessage);
    }
  }, [errors])

  const isNewRecord = () => state.id === null

  const parkingBaseService = useMemo(() => {
    return baseServices.find((bs) => bs.attributes.base_service_type === 'parking')
  }, [baseServices])

  const carWashBaseService = useMemo(() => {
    return baseServices.find((bs) => bs.attributes.base_service_type === 'car-wash')
  }, [baseServices])

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    let vehicleParkingAllowed = false;
    let vehicleWashingAllowed = false;

    try {
      const { data } = await axiosInstance['get'](`/partners/${partnerInputRef.current.value}.json`)

      if(data.errors) {
        setErrors(data.errors)
        alert('Error while fetching partner info!')
      } else {
        vehicleParkingAllowed = data['vehicle_parking']
        vehicleWashingAllowed = data['vehicle_washing']
      }
    } catch (error) {
      alert('Error while fetching partner info!', error)
    }

    if (isParkingBaseServiceSelected && !state.parking_products.length) {
      alert(t('sites.errors.parking_product_missing'))
      return
    } else if (isCarWashBaseServiceSelected && !state.car_wash_products.length) {
      alert(t('sites.errors.truck_wash_product_missing'))
      return
    } else if (isParkingBaseServiceSelected && state.parking_products.length && !vehicleParkingAllowed) {
      alert(t('sites.errors.parking_unallowed_by_partner'))
      return
    } else if (isCarWashBaseServiceSelected && state.car_wash_products.length && !vehicleWashingAllowed) {
      alert(t('sites.errors.washing_unallowed_by_partner'))
      return
    }

    saveSite()
  }

  const confirmSubmit = (e) => {
    let confirmText = t('bookings.toggle_auto_accept_button.confirm_auto_accept_on')
    if (confirm(confirmText)) {
      handleFormSubmit(e)
    }
    return false
  }

  const saveSite = async () => {
    const method          = isNewRecord() ? 'post' : 'put'
    const dataDestination = isNewRecord() ? '/sites.json' : `/sites/${state.id}.json`

    const params = {
      site: {
        partner_id:        partnerInputRef.current.value,
        safety_level: safetyLevelInputRef.current.value,
        selected_parking_safety_answers: $(selectedParkingSafetyAnswersInputRef.current).val(),
        address:           state.address,
        country:           state.country.value,
        enable_service_booking: state.enable_service_booking,
        auto_accept_bookings: state.auto_accept_bookings,
        phone:             state.phone,
        lat:               state.lat,
        lng:               state.lng,
        service_ids:       state.service_ids,
        parking_products:  state.parking_products,
        car_wash_products: state.car_wash_products,
        bank_account:      state.bank_account,
        site_base_services_attributes: parsedSiteBaseServiceState(),
        description:       state.description,
        important_information: state.important_information
      }
    }

    try {
      const { data } = await axiosInstance[method](dataDestination, params)

      if(data.errors) {
        setErrors(data.errors)
        alert('New record could not be created!')
      } else {
        setErrors({})
        window.location.href = data.redirect_url
      }
    } catch (error) {
      alert('New record could not be created!', error)
    }
  }

  const parsedSiteBaseServiceState = () => {
    const newState = {...siteBaseServiceState}

    // TODO: Make it more compact?
    if (isParkingBaseServiceSelected) {
      newState.parking.base_service_id = parseInt(parkingBaseService.id)
      newState.parking.working_hours_attributes = prepareWorkingHourAttributes(newState.parking.working_hours)
      delete newState.parking._destroy
    } else {
      newState.parking._destroy = true
    }

    if (isCarWashBaseServiceSelected) {
      newState.truckWash.base_service_id = parseInt(carWashBaseService.id)
      newState.truckWash.working_hours_attributes = prepareWorkingHourAttributes(newState.truckWash.working_hours)
      delete newState.truckWash._destroy
    } else {
      newState.truckWash._destroy = true
    }

    const parsedState = Object.values({...newState}).map((sbs) => {
      const serviceState = {...sbs}
      if (serviceState.payment_method) serviceState.payment_method = sbs.payment_method.value
      if (serviceState.discount_value) serviceState.discount_value = parseFloat(serviceState.discount_value)
      return serviceState
    }).filter(sbs => Object.keys(sbs).length !== 0)

    return parsedState
  }

  const prepareWorkingHourAttributes = (workingHours) => {
    const result = {}

    Object.keys(workingHours).forEach((key, i) => {
      const workingHoursThatDay = workingHours[key]
      if(workingHoursThatDay) {
        result[key] = workingHoursThatDay.join(',')
      } else {
        result[key] = null
      }
    })
    return result
  }

  const handleFieldChange = (fieldName) => (e) => {
    const newState = {...state}

    const valueFields = ['country', 'parking_payment_method', 'car_wash_payment_method']

    if(valueFields.includes(fieldName)) {
      newState[fieldName] = e
    } else {
      newState[fieldName] = e.target.value
    }

    setState(newState)
  }

  const handleIdFieldChange = (fieldName) => (ids) => {
    const newState      = {...state}
    newState[fieldName] = ids

    setState(newState)
  }

  const handleCheckboxChange = (fieldName) => (e) => {
    const newState      = {...state}
    newState[fieldName] = !(e.target.value === 'true')

    setState(newState)
  }

  const handleSelectedProductChange = (products) => {
    const newState = {...state}
    newState.parking_products = products

    setState(newState)
  }
  const handleSelectedWashProductChange = (products) => {
    const newState = {...state}
    newState.car_wash_products = products

    setState(newState)
  }

  const handleServiceCheckboxChange = (service, fieldName) => (e) => {
    const serviceState = siteBaseServiceState[service]
    serviceState[fieldName] = !(e.target.value === 'true')

    const newState = {...siteBaseServiceState, [service]: {...serviceState}}
    setSiteBaseServiceState(newState)
  }

  const handleServiceFieldChange = (service, fieldName, selectField = false) => (e) => {
    const serviceState = siteBaseServiceState[service]
    if (selectField) {
      serviceState[fieldName] = e
    } else {
      serviceState[fieldName] = e.target.value
    }

    const newState = {...siteBaseServiceState, [service]: {...serviceState}}
    setSiteBaseServiceState(newState)
  }

  const handleWorkingHourChange = (weekday, service) => (type, value) => {
    const newWorkingHours = {...siteBaseServiceState[service]['working_hours']}
    const weekdayValue = newWorkingHours[weekday] || []

    let newWeekdayValue

    if (type == 'start_at') {
      newWeekdayValue = [value, weekdayValue[1]]
    } else {
      newWeekdayValue = [weekdayValue[0], value]
    }
    newWorkingHours[weekday] = newWeekdayValue

    const newState = {...siteBaseServiceState[service], working_hours: newWorkingHours}

    setSiteBaseServiceState({
      ...siteBaseServiceState, [service]: {...newState}
    })
  }

  const handleWorkingHourTypeChange = (e, service) => {
    const newState = {...siteBaseServiceState[service]}
    newState['working_247'] = !newState['working_247']

    setSiteBaseServiceState({
      ...siteBaseServiceState, [service]: {...newState}
    })
  }

  const isPaymentMethodBooking = useMemo(() => {
    const bookingMethods = ['booking', 'plate_nr_and_booking']
    return bookingMethods.includes((siteBaseServiceState.parking.payment_method || {}).value)
  }, [siteBaseServiceState.parking.payment_method])

  const isPaymentMethodBookingForTruckWash = useMemo(() => {
    const bookingMethods = ['booking', 'plate_nr_and_booking']
    return bookingMethods.includes((siteBaseServiceState.truckWash.payment_method || {}).value)
  }, [siteBaseServiceState.truckWash.payment_method])

  const isParkingBaseServiceSelected = useMemo(() => {
    return state.base_service_ids.includes(parseInt(parkingBaseService.id))
  }, [state.base_service_ids, parkingBaseService.id])

  const isCarWashBaseServiceSelected = useMemo(() => {
    return state.base_service_ids.includes(parseInt(carWashBaseService.id))
  }, [state.base_service_ids, carWashBaseService.id])

  const submitButtonText = () => {
    return t(`helpers.submit.${isNewRecord() ? 'create' : 'update'}`, { model: 'Site' })
  }

  return (
    <div className='site-address-form'>
      <div className='simple_form'>
        <FormField
          fieldName='address'
          value={state.address || ''}
          onChange={handleFieldChange('address')}
        />
        <Select2Field
          fieldName='country'
          data={countries}
          value={state.country || ''}
          onChange={handleFieldChange('country')}
          required
          errorMessage={errors['country']}
        />
        <FormField
          fieldName='phone'
          value={state.phone || ''}
          onChange={handleFieldChange('phone')}
        />
        <FormField
          fieldName='bank_account'
          value={state.bank_account || ''}
          onChange={handleFieldChange('bank_account')}
          additionalText={t('sites.form.bank_account_leave_empty')}
        />
        <CoordinatesFields
          apikeyValue={googleApiKey}
          lat={state.lat || ''}
          lng={state.lng || ''}
          onChangeLat={handleFieldChange('lat')}
          onChangeLng={handleFieldChange('lng')}
        />
        <CheckboxField
          fieldName='enable_service_booking'
          value={state.enable_service_booking || false}
          onChange={handleCheckboxChange('enable_service_booking')}
        />
        <CheckboxField
          fieldName='auto_accept_bookings'
          value={state.auto_accept_bookings || false}
          onChange={handleCheckboxChange('auto_accept_bookings')}
          disabled={!state.enable_service_booking}
        />
        <BaseServicesSelection
          baseServices={baseServices}
          selectedBaseServices={state.base_service_ids}
          onChange={handleIdFieldChange('base_service_ids')}
        />
        <TextAreaField
          fieldName='description'
          value={state.description || ''}
          onChange={handleFieldChange('description')}
          maxLength={300}
        />
        <TextAreaField
          fieldName='important_information'
          value={state.important_information || ''}
          onChange={handleFieldChange('important_information')}
          maxLength={300}
        />
        {isParkingBaseServiceSelected && (
          <div id='parking_block' className='mt-4 border-top'>
            <h5 className="mb-4 pt-3">{t('sites.form.parking')}</h5>
            <WorkingHours
              working247={siteBaseServiceState.parking.working_247}
              workingHours={siteBaseServiceState.parking.working_hours}
              onChange247={handleWorkingHourTypeChange}
              onChange={handleWorkingHourChange}
              baseService={'parking'}
            />
            <div className="short-field">
              <FormField
                fieldName='parking_discount_value'
                value={ siteBaseServiceState.parking.discount_value || ''}
                onChange={handleServiceFieldChange('parking', 'discount_value')}
                fieldLegend={<legend className="col-form-label pt-0">{t('sites.form.discount_value')}</legend>}
                label={t('site.parking_discount_value')}
              />
            </div>
            <Select2Field
              fieldName='parking_payment_method'
              data={paymentMethods}
              value={siteBaseServiceState.parking.payment_method || ''}
              onChange={handleServiceFieldChange('parking', 'payment_method', true)}
              required
              errorMessage={errors['site_base_services.parking_payment_method']}
            />
            <ParkingProducts
              parkingProducts={parkingProducts}
              selectedParkingProducts={state.parking_products}
              currency={state.currency}
              onChange={handleSelectedProductChange}
            />
            <CheckboxField
              fieldName='parking_smartphone_only'
              value={siteBaseServiceState.parking.smartphone_access_only || false}
              onChange={handleServiceCheckboxChange('parking', 'smartphone_access_only')}
              fieldLegend={<legend className="col-form-label pt-0">{t('sites.form.smartphone_only')}</legend>}
              additionalClass={'check_boxes'}
              label={t('sites.form.smartphone_only_label')}
            />
            <div className="short-field">
              <FormField
                fieldName='parking_spaces'
                value={ siteBaseServiceState.parking.parking_spaces || ''}
                fieldLegend={<legend className="col-form-label pt-0">{t('sites.form.parking_spaces')}</legend>}
                onChange={handleServiceFieldChange('parking', 'parking_spaces')}
                label={t('sites.form.parking_spaces')}
              />
            </div>
            {isPaymentMethodBooking && (
              <FormField
                fieldName='special_requiremt_comment'
                value={siteBaseServiceState.parking.special_requirement_comment || ''}
                onChange={handleServiceFieldChange('parking', 'special_requirement_comment')}
              />
            )}
            {!isCarWashBaseServiceSelected && (
              <div className='border-bottom mb-4' />
            )}
          </div>
        )}
        {isCarWashBaseServiceSelected && (
          <div id='truck_wash_block' className='mt-4 mb-4 border-top border-bottom'>
            <h5 className="mb-4 pt-3">{t('sites.form.truck_wash')}</h5>
            <WorkingHours
              working247={siteBaseServiceState.truckWash.working_247}
              workingHours={siteBaseServiceState.truckWash.working_hours}
              onChange247={handleWorkingHourTypeChange}
              onChange={handleWorkingHourChange}
              baseService={'truckWash'}
            />
            <div className="short-field">
              <FormField
                fieldName='truckwash_discount_value'
                value={ siteBaseServiceState.truckWash.discount_value || ''}
                onChange={handleServiceFieldChange('truckWash', 'discount_value')}
                fieldLegend={<legend className="col-form-label pt-0">{t('sites.form.discount_value')}</legend>}
                label={t('site.truckwash_discount_value')}
              />
            </div>
            <Select2Field
              fieldName='car_wash_payment_method'
              data={paymentMethods}
              value={siteBaseServiceState.truckWash.payment_method || ''}
              onChange={handleServiceFieldChange('truckWash', 'payment_method', true)}
              required
              errorMessage={errors['site_base_services.car_wash_payment_method']}
            />
            <CarWashProducts
              washTypes={props.wash_types}
              carWashProducts={carWashProducts}
              selectedCarWashProducts={state.car_wash_products}
              onChange={handleSelectedWashProductChange}
              carWashSequence={props.car_wash_sequence}
            />
            {isPaymentMethodBookingForTruckWash && (
              <FormField
                fieldName='special_requiremt_comment'
                value={siteBaseServiceState.truckWash.special_requirement_comment || ''}
                onChange={handleServiceFieldChange('truckWash', 'special_requirement_comment')}
              />
            )}
          </div>
        )}
        <ServicesSelection
          services={services}
          selectedServices={state.service_ids}
          onChange={handleIdFieldChange('service_ids')}
        />
        <input
          type="submit"
          value={submitButtonText()}
          className="btn btn-primary"
          data-disable-with={submitButtonText()}
          onClick={state.auto_accept_bookings ? confirmSubmit : handleFormSubmit}
        />
      </div>
    </div>
  )
}
