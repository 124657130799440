import React, { Fragment, useState, useEffect } from "react"
import { t } from 'i18n'

export default function ServicesSelection (props) {
  const [selected, setSelected] = useState(props.selectedServices)

  useEffect(() => {
    props.onChange(selected)
  }, [selected])

  const handleChange = (e) => {
    const serviceId   = parseInt(e.target.value)
    const newSelected = [...selected]

    if(newSelected.indexOf(serviceId) >= 0){
      newSelected.splice(newSelected.indexOf(serviceId), 1)
    } else {
      newSelected.push(serviceId)
    }

    setSelected(newSelected)
  }

  const renderService = (service) => {
    const id = `site_service_ids_${service.id}`

    return (
      <div className="form-check" key={service.id}>
        <input
          className="form-check-input check_boxes optional"
          type="checkbox"
          value={service.id}
          name="site[service_ids][]"
          id={id}
          onChange={handleChange}
          checked={selected.indexOf(parseInt(service.id)) >= 0}
        />
        <label className="form-check-label collection_check_boxes" htmlFor={id}>{service.attributes.name}</label>
      </div>
    )
  }

  return (
    <fieldset className="form-group check_boxes optional site_service_ids">
      <legend className="col-form-label pt-0">
        {t('sites.form.services')}
      </legend>
      {props.services.map((service) => renderService(service))}
    </fieldset>
  )
}
