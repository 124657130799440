import React, { Fragment } from "react"
import { t }               from 'i18n'

export default function WorkingHours (props) {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  const allwaysOpen = () => {
    return props.working247
  }

  const getStartAt = (weekday) => {
    return props.workingHours[weekday] ? props.workingHours[weekday][0] || '' : ''
  }

  const getEndAt = (weekday) => {
    return props.workingHours[weekday] ? props.workingHours[weekday][1] || '' : ''
  }

  const weekdayFields = (weekDay) => {
    const inputClassName = "form-control string optional short-field"

    return (
      <div className="form-group optional form-group-valid" key={`${props.baseService}_${weekDay}`}>
        <label className="workin-hours-label" htmlFor={`${props.baseService}_${weekDay}_start_at`}>{t(`activerecord.attributes.working_hours.${weekDay}`)}</label>
        <input
          type="text"
          name={`${props.baseService}_${weekDay}_start_at`}
          id={`${props.baseService}_${weekDay}_start_at`}
          className={inputClassName}
          value={getStartAt(weekDay)}
          onChange={(e) => props.onChange(weekDay, props.baseService)('start_at', e.target.value)}
        />
        <input
          type="text"
          name={`${props.baseService}_${weekDay}_end_at`}
          id={`${props.baseService}_${weekDay}_end_at`}
          className={inputClassName}
          value={getEndAt(weekDay)}
          onChange={(e) => props.onChange(weekDay, props.baseService)('end_at', e.target.value)}
        />
      </div>
    )
  }

  return (
    <Fragment>
      <fieldset className="form-group boolean optional site_working_247">
        <div className="form-check">
          <input
            className="form-check-input boolean optional"
            type="checkbox"
            value="1"
            onChange={(e) => props.onChange247(e, props.baseService)}
            id={`${props.baseService}_site_working_247`}
            checked={props.working247 == true}
          />
          <label className="form-check-label boolean optional" htmlFor={`${props.baseService}_site_working_247`}>Working 24/7</label>
        </div>
      </fieldset>
      {
        !allwaysOpen() && <div>
          {weekDays.map((wkd) => weekdayFields(wkd))}
        </div>
      }
    </Fragment>
  )
}
