import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'agreed1', 'agreed2',
    'representativeName', 'representativePosition',
    'submitButton'
  ]

  connect() {
    super.connect()

    this.toggleSubmitButton()

    this.representativeNameTarget.addEventListener('change', this.toggleSubmitButton.bind(this))
    this.representativePositionTarget.addEventListener('change', this.toggleSubmitButton.bind(this))
    this.agreed1Target.addEventListener('change', this.toggleSubmitButton.bind(this))
    this.agreed2Target.addEventListener('change', this.toggleSubmitButton.bind(this))
  }

  toggleSubmitButton() {
    this.submitButtonTarget.disabled = !(
      this.representativeNameTarget.value &&
      this.representativePositionTarget.value &&
      this.agreed1Target.checked &&
      this.agreed2Target.checked
    )
  }
}
