import { Controller } from 'stimulus'
import axiosInstance  from 'axios_instance'

export default class InvoiceNumberController extends Controller {
  initialize() {
    this.path       = this.element.dataset.path
    this.generating = this.element.dataset.generating
  }

  connect() {
    this.element.addEventListener('check-invoice-regenerate-status', this.handleCheckStatusEvent.bind(this))

    if (this.generating === 'true') {
      this.toggleLoader()
      this.startChecking()
    }
  }

  handleCheckStatusEvent(e) {
    this.toggleLoader()
    this.startChecking()
  }

  startChecking() {
    setTimeout(() => {
      this.checkStatus()
    }, 1000)
  }

  stopChecking(file) {
    if(!file) return

    const parentTr     = this.element.parentElement.parentElement
    const downloadLink = this.element.parentElement.getElementsByClassName('download-link')[0]

    downloadLink.setAttribute('title', file.name)
    downloadLink.setAttribute('href', file.path)

    // set new url to clickable table row
    parentTr.dataset.href = file.url
    const event = new Event('reinitialize-with-new-url')
    parentTr.dispatchEvent(event)

    this.toggleLoader()
  }

  async checkStatus() {
    try {
      const { data } = await axiosInstance.get(this.path)

      if (data.status === 'in_progress') {
        this.startChecking()
      } else {
        this.stopChecking(data.file)
      }
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  toggleLoader() {
    this.element.classList.toggle('hidden')
  }
}
