export function urlSearchParams(formData) {
  const data = formEntries(formData)
  return urlEncodeEnties(data)
}

export function formEntries(formData) {
  const formDataObj = new FormData(formData);
  const data = [...formDataObj.entries()];
  return data
}

export function urlEncodeEntries(entries) {
  return entries.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`).join('&');
}

export function mergeWithcurrentParams(currentParams, formParams) {
  var newArray = []

  currentParams.forEach(currParam => {
    var exists = formParams.filter(fParam => fParam[0] == currParam[0])

    if (exists.length == 0) {
      newArray.push(currParam)
    }
  });

  return formParams.concat(newArray);
}

export function updateSearchParams(formData, skipParams) {
  const url             = new URL(window.location);
  const skippableParams = (skipParams || '').split(',')
  const searchParams    = new URLSearchParams(url.search);
  const currentParams   = [...searchParams.entries()].filter((param) => {
    return skippableParams.indexOf(param[0]) < 0
  })

  const formParams = formEntries(formData)
  const formParamsWithoutDuplicates = mergeWithcurrentParams(currentParams, formParams)

  const newUrlParams = urlEncodeEntries(formParamsWithoutDuplicates)

  window.history.pushState(null, document.title, `?${newUrlParams}`)
}
